@if $use-ln-progress-bar {
  $duration: 2s;

  @keyframes ln-animation-move {
    100% {
      transform: translateX(300%);
    }
  }

  .ln-c-progress-bar {
    background-color: rgba($ln-color-alpha, 0.2);
    flex-shrink: 0;
    height: $ln-space;
    overflow: hidden;
    position: relative;
    width: 100%;

    .ln-c-progress-bar__fill,
    &::before,
    &::after {
      background-color: $ln-color-alpha;
      height: 100%;
    }

    &::before,
    &::after {
      animation: ln-animation-move $duration infinite ease-in-out;
      content: '';
      left: -50%;
      position: absolute;
      top: 0;
      width: 50%;
    }

    &::after {
      animation-delay: $duration / 2;
    }

    &.ln-c-progress-bar--light {
      background-color: rgba($ln-color-light-highlight, 0.2);

      &::before,
      &::after {
        background-color: $ln-color-light-highlight;
      }
    }

    &.ln-c-progress-bar--dark {
      background-color: rgba($ln-color-dark-highlight, 0.2);

      &::before,
      &::after {
        background-color: $ln-color-dark-highlight;
      }
    }

    &.ln-c-progress-bar--small {
      height: $ln-space-half;
    }

    &.ln-c-progress-bar--standalone {
      height: $ln-space-half;
      max-width: (30 * $ln-space-px);

      @include ln-respond-to($ln-size-lg-breakpoint) {
        height: $ln-space;
      }
    }

    &.ln-c-progress-bar--determinate {
      animation: none;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .ln-c-progress-bar__fill {
    transition: width $ln-transition-duration ease-in-out;
  }
}
