@if $use-ln-card {
  .ln-c-card {
    background-color: $ln-color-white;
  }

  .ln-c-card--soft {
    @include ln-soft;
  }

  .ln-c-card--transparent {
    background-color: $ln-color-transparent;
  }

  .ln-c-card--alpha {
    background-color: $ln-color-alpha;
    color: $ln-color-text-on-alpha;
  }

  .ln-c-card--beta {
    background-color: $ln-color-beta;
    color: $ln-color-text-on-beta;
  }

  .ln-c-card--rounded {
    border-radius: $ln-border-radius;
  }
}
