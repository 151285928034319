@mixin ln-c-accordion-build-segments {
  background-color: $ln-color-accordion-background;
  border-bottom: $ln-border-size solid $ln-color-accordion-border;

  > .ln-c-accordion__head {
    align-items: center;
    color: $ln-color-text;
    display: flex;
    min-height: $ln-size-def;
    padding-bottom: $ln-space;
    padding-top: $ln-space;
    position: relative;
    vertical-align: middle;

    @include ln-hover-focus-within {
      color: $ln-color-text-active;
    }
  }

  > .ln-c-accordion__head > .ln-c-accordion__title {
    @include ln-font($ln-font-button);
    @include ln-soft-left;

    display: inline-block;
    font-weight: bold;
    margin: 0;
    padding-right: ($ln-space-x2 * 2) + $ln-icon-size-def;

    // Needed to control width when inside of table
    width: 100%;
  }

  > .ln-c-accordion__head > .ln-c-accordion__toggle {
    display: block;
  }

  > .ln-c-accordion__body {
    height: 0;
    overflow: hidden;
    transition: height $ln-transition-duration;

    > .ln-c-accordion {
      @include ln-set-spacing(
        $size: 'def',
        $direction: 'sides',
        $type: 'margin',
        $important: false,
        $neg: true
      );
    }

    > .ln-c-accordion:first-child {
      @include ln-set-spacing(
        $size: 'def',
        $direction: 'top',
        $type: 'margin',
        $important: false,
        $neg: true
      );
    }

    > .ln-c-accordion:last-child {
      @include ln-set-spacing(
        $size: 'def',
        $direction: 'bottom',
        $type: 'margin',
        $important: false,
        $neg: true
      );

      border-bottom: none;
    }
  }

  &.is-open {
    > .ln-c-accordion__head {
      > .ln-c-accordion__title {
        color: $ln-color-text-alpha;
      }
    }

    > .ln-c-accordion__head {
      @include ln-hover-focus-within {
        > .ln-c-accordion__title {
          color: $ln-color-text-alpha-active;
        }
      }
    }

    > .ln-c-accordion__body {
      height: auto;
      overflow: visible;

      @include ln-soft;
    }

    // reason: Requires the high-specificity selector below to allow nesting of foldable components.
    // stylelint-disable-next-line
    > .ln-c-accordion__head > .ln-c-accordion__toggle > .ln-c-accordion__icon {
      transform: rotate(180deg) translateY(50%);
    }
  }

  &.ln-c-accordion--standalone {
    border-bottom: none;

    &:first-child {
      border-top: none;
    }

    @include ln-push-ends;
  }

  &.ln-c-accordion--light,
  &.ln-c-accordion--dark {
    background-color: $ln-color-transparent;

    &:last-child {
      border-bottom: none;
    }
  }

  &.ln-c-accordion--light {
    > .ln-c-accordion__head {
      color: $ln-color-text-light;

      @include ln-hover-focus-within {
        color: $ln-color-text-light-active;
      }
    }

    &.is-open > .ln-c-accordion__head {
      > .ln-c-accordion__title {
        color: $ln-color-text-light-active;
      }

      @include ln-hover-focus-within {
        > .ln-c-accordion__title {
          color: $ln-color-text-light;
        }
      }
    }
  }

  &.ln-c-accordion--dark {
    > .ln-c-accordion__head {
      color: $ln-color-text-dark;

      @include ln-hover-focus-within {
        color: $ln-color-text-dark-active;
      }
    }

    &.is-open > .ln-c-accordion__head {
      > .ln-c-accordion__title {
        color: $ln-color-text-dark-active;
      }

      @include ln-hover-focus-within {
        > .ln-c-accordion__title {
          color: $ln-color-text-dark;
        }
      }
    }
  }
}

@if $use-ln-accordion {
  .ln-c-accordion:not([class~='ln-c-accordion@']) {
    @include ln-c-accordion-build-segments;
  }

  @each $key, $value in $ln-breakpoints {
    .ln-c-accordion\@max-#{$key} {
      @include ln-respond-to('max-#{$key}') {
        @include ln-c-accordion-build-segments;
      }
    }
  }

  .ln-c-accordion__toggle {
    @include ln-focus-transition;

    background: $ln-color-transparent;
    bottom: 0;
    color: currentColor;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .ln-c-accordion__icon {
    position: absolute;
    right: $ln-space-x2;
    top: 50%;
    transform: rotate(0) translateY(-50%);
  }
}
