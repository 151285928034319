@if $use-ln-navigation-toggle {
  $burger-width: 24px;
  $burger-height: 3px;

  .ln-c-navigation-toggle__item,
  .ln-c-navigation-toggle__item::before,
  .ln-c-navigation-toggle__item::after {
    background: $ln-color-black;
    content: ' ';
    height: $burger-height;
    left: 50%;
    margin-left: -($burger-width * 0.5);
    margin-top: -($burger-height * 0.5);
    position: absolute;
    top: 50%;
    transition: all $ln-transition-duration;
    width: $burger-width;

    .ln-navigation-toggle--light & {
      background: $ln-color-light;
    }

    .ln-navigation-toggle--dark & {
      background: $ln-color-dark;
    }
  }

  .ln-c-navigation-toggle__item::before {
    transform: translateY(-($burger-height * 2));
  }

  .ln-c-navigation-toggle__item::after {
    transform: translateY(($burger-height * 2));
  }

  .ln-c-header.is-open .ln-c-navigation-toggle__item {
    $rotation: 45deg;

    background: $ln-color-transparent;
    transform: rotate(-$rotation);

    &::before {
      transform: translateY(0);
    }

    &::after {
      transform: rotate($rotation * 2);
    }
  }
}
