/* stylelint-disable max-nesting-depth */
@if $use-ln-section {
  @each $key, $breakpoints in $ln-section-soft-variants {
    $soft-modifier: '';

    @if ($key != 'def') {
      $soft-modifier: '--#{$key}';
    }

    .ln-o-section#{$soft-modifier} {
      @each $key, $value in $breakpoints {
        @if ($key == 'def') {
          padding-bottom: $value;
        } @else {
          @include ln-respond-to($key) {
            padding-bottom: $value;
          }
        }
      }
    }
  }
}
