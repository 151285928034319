/// @deprecated
@mixin ln-spacing {
  @each $key, $value in $ln-spacing-percentages {
    @if $key == 'def' {
      font-size: $value;
    } @else {
      @if map-has-key($ln-breakpoints, $key) {
        /* stylelint-disable-next-line max-nesting-depth */
        @include ln-respond-to($key) {
          font-size: $value;
        }
      } @else {
        @warn 'No "#{$key}" breakpoint found';
      }
    }
  }
}

@mixin ln-set-spacing-rule(
  $type,
  $size: 'def',
  $important: false,
  $neg: false
) {
  $important-str: '';
  $spacing: map-get($ln-scaled-space, $size);

  @if ($important) {
    $important-str: '!important';
  }

  @each $breakpoint, $value in $spacing {
    @if ($neg) {
      $value: -$value;
    }

    @if ($breakpoint == 'def') {
      #{$type}: $value #{$important-str};
    } @else {
      @include ln-respond-to($breakpoint) {
        #{$type}: $value #{$important-str};
      }
    }
  }
}

@mixin ln-set-spacing(
  $size: 'def',
  $direction: 'all',
  $type: 'padding',
  $important: false,
  $neg: false
) {
  $direction-list: '';
  $separator: '-';
  $important-str: '';
  $spacing: map-get($ln-scaled-space, $size);

  @if ($important) {
    $important-str: '!important';
  }

  @if ($direction == 'ends') {
    $direction-list: 'top', 'bottom';
  } @else if ($direction == 'sides') {
    $direction-list: 'left', 'right';
  } @else if ($direction == null or $direction == 'all') {
    $direction-list: '';
    $separator: '';
  } @else {
    $direction-list: $direction;
  }

  @each $breakpoint, $value in $spacing {
    @if ($neg) {
      $value: -$value;
    }

    @if ($breakpoint == 'def') {
      @each $direction-string in $direction-list {
        #{$type}#{$separator}#{$direction-string}: #{$value} #{$important-str};
      }
    } @else {
      @include ln-respond-to($breakpoint) {
        // stylelint-disable-next-line max-nesting-depth
        @each $direction-string in $direction-list {
          #{$type}#{$separator}#{$direction-string}: #{$value}
            #{$important-str};
        }
      }
    }
  }
}

@mixin ln-soft($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'all', 'padding', $important);
}

@mixin ln-soft-top($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'top', 'padding', $important);
}

@mixin ln-soft-right($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'right', 'padding', $important);
}

@mixin ln-soft-bottom($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'bottom', 'padding', $important);
}

@mixin ln-soft-left($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'left', 'padding', $important);
}

@mixin ln-soft-ends($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'ends', 'padding', $important);
}

@mixin ln-soft-sides($size: 'def', $important: false) {
  @include ln-set-spacing($size, 'sides', 'padding', $important);
}

@mixin ln-push($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'all', 'margin', $important, $neg);
}

@mixin ln-push-top($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'top', 'margin', $important, $neg);
}

@mixin ln-push-right($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'right', 'margin', $important, $neg);
}

@mixin ln-push-bottom($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'bottom', 'margin', $important, $neg);
}

@mixin ln-push-left($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'left', 'margin', $important, $neg);
}

@mixin ln-push-ends($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'ends', 'margin', $important, $neg);
}

@mixin ln-push-sides($size: 'def', $important: false, $neg: false) {
  @include ln-set-spacing($size, 'sides', 'margin', $important, $neg);
}
