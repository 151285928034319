$ln-assets-url: "~luna-style";
@import "luna-react/scss/style";
@import "../../css/user-defined";

.hierarchy-item-container {
  height: 450px;
  overflow-y: scroll;
}

.hierarchy-item {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  transition: none;
}

.hierarchy-item:hover,
.hierarchy-item:focus,
.hierarchy-item-selected {
  background-color: $ln-color-orange;
  color: $ln-color-white !important;
  box-shadow: none;
}

.hierarchy-item-notActive {
  color: $ud-inactive-colour;
}

.no-padding:not(:last-child) {
  padding: 0px !important;
}

.no-bottom-padding {
  padding-bottom: 0px !important;
}

.status-container {
  display: flex;
}

.status-container > div:last-child {
  margin-right: 0;
}

.progress-indicator {
  flex-grow: 10;
}

.label {
  display: block;
  line-height: 48px;
  margin: 0px;
}
.code-generator{
  margin-bottom: -40px !important; //to adjust the gap between 2 elements
  margin-top: -24px !important;
}

.class-code-button{
  padding-left: 15px;
}

.ln-c-field-info--error{
  font-size: small;
}