@mixin ln-link-color-variant($text, $text-active, $bg) {
  color: $text;

  &.ln-c-link--cta::after {
    background-color: $text;
  }

  @include ln-hocus {
    background-color: $bg;
    color: $text-active;

    &.ln-c-link--cta::after {
      background-color: $text-active;
    }
  }
}

@if $use-ln-label {
  $link-colors: (
    alpha: (
      text: $ln-color-text-alpha,
      text-active: $ln-color-text-alpha-active,
      bg: $ln-color-alpha-background-highlight,
      bold: true,
    ),
    beta: (
      text: $ln-color-text-beta,
      text-active: $ln-color-text-beta-active,
      bg: $ln-color-beta-background-highlight,
    ),
    light: (
      text: $ln-color-text-light,
      text-active: $ln-color-text-light-active,
      bg: $ln-color-light-background-highlight,
    ),
    dark: (
      text: $ln-color-text-dark,
      text-active: $ln-color-text-dark-active,
      bg: $ln-color-dark-background-highlight,
    ),
  );

  .ln-c-link {
    @include ln-transition-properties-with-focus((background-color, color));

    background-color: transparent;
    color: $ln-color-text;

    @include ln-hocus {
      background-color: $ln-color-base-background-highlight;
      color: $ln-color-text-active;
    }

    &.ln-c-link--bare:not(.ln-c-link--cta) {
      text-decoration: none;

      @include ln-hocus {
        text-decoration: underline;
      }
    }

    &.ln-c-link--cta {
      @include ln-font($ln-font-button);

      position: relative;
      text-decoration: none;

      &::after {
        background-color: $ln-color-text;
        bottom: 0;
        content: '';
        height: $ln-border-size-large;
        left: 0;
        position: absolute;
        right: 0;
        transform: scaleX(1);
        transition-duration: $ln-transition-duration;
        transition-property: background-color, transform;
      }

      @include ln-hocus {
        &::after {
          background-color: $ln-color-text-active;
        }
      }

      &.ln-c-link--bare {
        &::after {
          transform: scaleX(0);
        }

        @include ln-hocus {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  @each $variant, $colors in $link-colors {
    &.ln-c-link--#{$variant} {
      @include ln-link-color-variant(
        map-get($colors, 'text'),
        map-get($colors, 'text-active'),
        map-get($colors, 'bg')
      );

      @if map-get($colors, 'bold') {
        font-weight: $ln-font-weight-bold;
      }
    }
  }
}
