@if $use-ln-input-group {
  $input-border-width: $ln-border-size-large;
  $input-element-width: calc(
    #{$ln-space-x2 * 2} + #{map-get($ln-icon-sizes, 'def')}
  );

  .ln-c-input-group {
    display: flex;
    min-height: $ln-size-def;
    position: relative;

    .ln-c-input-group__control {
      min-height: 0;
    }

    // IE10 + fix for text alignment https://github.com/philipwalton/flexbugs#flexbug-3
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: $ln-size-def;
    }
  }

  .ln-c-input-group__icon {
    height: map-get($ln-icon-sizes-fixed, 'def');
    left: calc(#{$ln-space-x2} + #{$ln-border-size});
    line-height: map-get($ln-icon-sizes-fixed, 'def');
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: map-get($ln-icon-sizes-fixed, 'def');
  }

  .ln-c-input-group__action {
    @include ln-transition-properties-with-focus((background-color, color));

    background-color: $ln-color-transparent;
    bottom: 0;
    min-height: auto;
    padding: 0 $ln-space-x2;
    position: absolute;
    right: 0;
    top: 0;

    @include ln-hocus-not-disabled {
      background: $ln-color-dark-background-highlight;
    }

    .ln-c-input-group--has-button & {
      right: $input-element-width;
    }
  }

  .ln-c-input-group__icon,
  .ln-c-input-group__action {
    color: $ln-color-text;
    z-index: $ln-layer-2;
  }

  .ln-c-input-group {
    @include ln-hover-focus-within {
      .ln-c-input-group__icon,
      .ln-c-input-group__action {
        color: $ln-color-text-active;
      }
    }
  }

  .ln-c-input-group__control {
    .ln-c-input-group--has-icon & {
      padding-left: $input-element-width;
    }

    .ln-c-input-group--has-button & {
      border-radius: $ln-border-radius 0 0 $ln-border-radius;
      border-right: none;
      margin-right: -$ln-border-size;
    }
  }

  .ln-c-input-group__button {
    border-radius: 0 $ln-border-radius $ln-border-radius 0;
    flex-shrink: 0;
  }

  .ln-c-input-group--has-action {
    @include ln-text-input;
    @include ln-text-input-validation-states;

    align-items: stretch;
    padding-right: 0;

    &.ln-c-input-group--has-icon {
      padding-left: 0;
    }

    .ln-c-input-group__action,
    .ln-c-input-group__icon {
      flex: 0 0 auto;
      position: static;
      transform: none;
    }

    .ln-c-input-group__icon {
      align-items: center;
      align-self: center;
      display: inline-flex;
      justify-content: center;
      justify-self: center;
      min-width: $input-element-width;
    }

    .ln-c-input-group__control {
      background: none;
      border: none;
      // Avoid specifity conflict with validation / interation states
      box-shadow: none !important;
      padding: 0;

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }
    }
  }
}
