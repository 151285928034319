$ln-assets-url: "~luna-style";
@import "luna-react/scss/style";
@import "../../css/user-defined";

$mapping-general-height: 60px;
$mapping-general-th-height: 30px;
$mapping-th-height-standard-name: 100px;
$mapping-top-header-height: 48px;

.inline-label {
  @include ln-font-fixed($ln-font-button);
  display: inline;
}

.inline-text {
  display: inline;
}

.button-container {
  padding-top: 3.35rem !important;
}

.button-container > *:last-child {
  float: right;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.mapping-table {
  display: flex;
}

.mapping table td div.inactive,
.mapping table th.inactive {
  color: $ln-color-grey;
}

.mapping {
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  border-right: 1px solid $ud-table-border-colour;
}

.mapping table {
  margin-bottom: 1px;
}

.mapping table th {
  position: relative;
  padding: 2px 3px;
  height: $mapping-general-th-height; 
  border-top: 1px solid $ud-table-border-colour;   
  min-width:100px;
  max-width:100px;
}

.mapping table th div {
  overflow: hidden;
}

.standard-name{
  font-size: small !important;
  height:$mapping-th-height-standard-name !important;
} 

.standard-description{
  font-size: 11px !important;
  height:$mapping-general-height;
} 

.standard-identifier{
  font-size: small;
}

.mapping table th:first-child{
  border-top: 1px solid white !important;
}

.mapping table th.grid-top-header {
  border-top: 1px solid $ud-table-border-colour !important;
  border-right: 1px solid $ud-table-border-colour;
  div{
    height: $mapping-top-header-height !important;
  }
}

.mapping table th.last {
  border-top: 1px solid $ud-table-border-colour;
}

.mapping table td {
  position: relative;
  overflow: hidden !important;
  max-height: $mapping-general-height !important;
  text-align: center;
  border-bottom: 1px solid $ud-table-border-colour;
  border-top: 1px solid $ud-table-border-colour;
}

//fix the first column 
.mapping table th:first-child,
.mapping table td:first-child{
  min-width: 300px;
  text-align: left !important;
  position:sticky !important;
  left:0; 
  z-index:1;
  background-color:white;

  border-left: 1px solid $ud-table-border-colour;
  border-right: 1px solid $ud-table-border-colour;
}

.mapping table td:first-child{
  div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 58px; //2px less than td height
    font-weight: bold;
  }
}

.mapping div.option {
  div.productList{
  padding-left: $ln-space-x2;
  }
  div.hierarchyCode{
    padding-right: $ln-space-x2;
  }
}

.mapping table td div.productList{
  padding-left: 3px;
  width:60%; 
}

.mapping div.hierarchyCode{
  min-width: 100px;
  max-width: 100px;
  border-left: 1px solid $ud-table-border-colour;
}

.mapping table td.last-document {
  border-right: 1px solid $ud-table-border-colour;
}

.mapping table th.last-document {
  border-right: 1px solid $ud-table-border-colour;
}

.mapping table td label {
  margin: auto;
}

.mapping-checkbox {
  display: block;
  background-color: $ln-color-white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  border: 1px solid $ln-color-grey;
  content: " ";
  width: 24px;
  height: 24px;
  margin: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color;
}

.mapping-checkbox-selected {
  background-size: 70%;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3E Line %3C/title%3E%3Cpath d='M1 6.714l4 4L12.714 1' stroke='%23FFFFFF' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-color: $ln-color-orange;
  border-color: $ln-color-orange;
}

.mapping-checkbox-inactive {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3E Line %3C/title%3E%3Cpath d='M1 6.714l4 4L12.714 1' stroke='%238E8E8E' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: 70%;
  background-color: $ln-color-grey-v-light;
  border-color: $ln-color-grey;
  border-radius: 1px;
}

.legend-div-spacing {
  padding-left: 30px;
}

.legend-label-spacing {
  padding-left: 15px;
}

.legend-inactive {
  font-weight: bold;
  color: $ln-color-grey;
}

.document-download{
  margin-top: 20px;
}

.general-document-checkbox label{
  font-size: medium !important;
}