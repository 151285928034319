/// The mixin checks if the value provided exists in `$ln-font-lookup` and returns the value
/// that should be mapped to
/// @group functions
/// @param {String} $font-name - font key
/// @return {String} font key existing in `$ln-fonts`
///
@function ln-font-find-key($font-name) {
  @if map-has-key($ln-font-lookup, $font-name) {
    @return map-get($ln-font-lookup, $font-name);
  } @else {
    @return $font-name;
  }
}

/// The mixin returns the generated font stack (size, family, weight, line-height) that is mapped
/// to that key. If an unknown key is passed in a warning will be logged to the terminal. This
/// variant will ignore the `$ln-fonts-scaled` map so the resulting type won't change across
/// breakpoints
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font-fixed('display-1');
///   }
///
@mixin ln-font-fixed($font-name, $important: false) {
  $font-key: ln-font-find-key($font-name);
  $important-str: '';

  @if ($important) {
    $important-str: '!important';
  }

  @if map-has-key($ln-fonts-transformed, $font-key) {
    @each $key, $value in map-get($ln-fonts-transformed, $font-key) {
      #{$key}: $value #{$important-str};
    }
  } @else {
    @warn '`#{$font-key}` doesn\'t exist in `$ln-fonts` map.';
  }
}

/// Uses `ln-font-fixed` to return the generated font stack that is mapped to that key. This will
/// also check the `$ln-fonts-scaled` and apply any size increases at the
/// `$ln-fonts-scaled-breakpoint` breakpoint
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font('display-1');
///   }
///
@mixin ln-font($font-name, $important: false) {
  $font-key: ln-font-find-key($font-name);

  @include ln-font-fixed($font-key, $important);

  @if map-has-key($ln-fonts-scaled, $font-key) {
    @include ln-respond-to($ln-fonts-scaled-breakpoint) {
      @include ln-font-fixed(map-get($ln-fonts-scaled, $font-key), $important);
    }
  }
}

/// Function to grab a specified font property for a key in the `$ln-fonts` map.
/// If an unknown key is passed in a warning will be logged to the terminal.
/// @group functions
/// @param {String} $font-name - font key
/// @param {String} $property - css property
/// @return {String} property value
///
@function ln-font-get-property($font-name, $property) {
  $font-key: ln-font-find-key($font-name);

  @if map-has-key($ln-fonts-transformed, $font-key) {
    @return ln-map-deep-get($ln-fonts-transformed, $font-key, $property);
  } @else {
    @warn '`#{$font-key}` doesn\'t exist in `$ln-fonts` map.';
  }
}

/// Helper function to grab line-height using `ln-font-get-property`.
/// @group functions
/// @param {String} $font-name - font key
/// @return {String} line height value
///
@function ln-line-height-fixed($font-name) {
  @return ln-font-get-property($font-name, 'line-height');
}

/// Helper function to grab font-size using `ln-font-get-property`.
/// @group functions
/// @param {String} $font-name - font key
/// @return {String} line height value
///
@function ln-font-size-fixed($font-name) {
  @return ln-font-get-property($font-name, 'font-size');
}

/// Uses `ln-font-size-fixed` function to add the `font-size` property of a type option in
/// `$ln-fonts` to a class. This variant will ignore the `$ln-fonts-scaled` map so the
/// resulting type won't change across breakpoints
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font-size-fixed('display-1');
///   }
///
@mixin ln-font-size-fixed($font-name, $important: false) {
  $important-str: '';

  @if ($important) {
    $important-str: '!important';
  }

  font-size: ln-font-size-fixed($font-name) #{$important-str};
}

/// Uses `ln-font-size-fixed` mixin to add the `font-size` property of a type option in
/// `$ln-fonts` to a class. This will also check the `$ln-fonts-scaled` and apply any size
/// increases at the `$ln-fonts-scaled-breakpoint` breakpoint
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font-size('display-1');
///   }
///
@mixin ln-font-size($font-name, $important: false) {
  $font-key: ln-font-find-key($font-name);

  @include ln-font-size-fixed($font-key, $important);

  @if map-has-key($ln-fonts-scaled, $font-key) {
    @include ln-respond-to($ln-fonts-scaled-breakpoint) {
      @include ln-font-size-fixed(
        map-get($ln-fonts-scaled, $font-key),
        $important
      );
    }
  }
}

/// Uses `ln-font-size-fixed` mixin and `ln-line-height-fixed` function to add the `font-size` and
/// `line-height` properties of a type option in `$ln-fonts` to a class. This variant will ignore
/// the `$ln-fonts-scaled` map so the resulting type won't change across breakpoints
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font-size-and-line-height-fixed('display-1');
///   }
///
@mixin ln-font-size-and-line-height-fixed($font-name, $important: false) {
  $important-str: '';

  @if ($important) {
    $important-str: '!important';
  }

  @include ln-font-size-fixed($font-name, $important);

  line-height: ln-line-height-fixed($font-name) #{$important-str};
}

/// Uses `ln-font-size-and-line-height` mixin to add the `font-size` and `line-height` properties
/// of a type option in `$ln-fonts` to a class. This will also check the `$ln-fonts-scaled` and
/// apply any size increases at the `$ln-fonts-scaled-breakpoint` breakpoint
/// @group mixins
/// @param {String} $font-name - font key existing in `$ln-fonts` map.
/// @example scss - Example input
///   h1 {
///     @include ln-font-size-and-line-height('display-1');
///   }
///
@mixin ln-font-size-and-line-height($font-name, $important: false) {
  $font-key: ln-font-find-key($font-name);

  @include ln-font-size-and-line-height-fixed($font-key, $important);

  @if map-has-key($ln-fonts-scaled, $font-key) {
    @include ln-respond-to($ln-fonts-scaled-breakpoint) {
      @include ln-font-size-and-line-height-fixed(
        map-get($ln-fonts-scaled, $font-key),
        $important
      );
    }
  }
}
