@mixin ln-gutters($size-key, $size-value, $matrix: null, $bp-classes: true) {
  $side: 'left';
  $matrix-class: '';
  $matrix-class-child: '';
  $matrix-class-modifier: '';

  @if $matrix {
    $side: 'bottom';
    $matrix-class-modifier: '--matrix';
    $matrix-class: '.ln-o-grid' + $matrix-class-modifier;
    $matrix-class-child: $matrix-class + ' > ';
  }

  @if ($size-key == 'def') {
    .ln-o-grid#{$matrix-class-modifier} {
      margin-#{$side}: -$size-value;
    }

    #{$matrix-class-child}.ln-o-grid__item {
      padding-#{$side}: $size-value;
    }
  } @else {
    #{$matrix-class}.ln-o-grid--gutter-#{$size-key} {
      margin-#{$side}: -$size-value;
    }

    #{$matrix-class}.ln-o-grid--gutter-#{$size-key} > .ln-o-grid__item {
      padding-#{$side}: $size-value;
    }
  }

  @if $use-ln-grid-gutter-breakpoints and $bp-classes {
    @each $bp in $ln-breakpoints {
      /* stylelint-disable max-nesting-depth */
      @for $i from 1 through 2 {
        $bp-key: nth($bp, 1);

        @if $i == 2 {
          $bp-key: 'max-' + nth($bp, 1);
        }

        @include ln-respond-to($bp-key) {
          #{$matrix-class}.ln-o-grid--gutter-#{$size-key}\@#{$bp-key} {
            margin-#{$side}: -$size-value;
          }

          #{$matrix-class}.ln-o-grid--gutter-#{$size-key}\@#{$bp-key}
            > .ln-o-grid__item {
            padding-#{$side}: $size-value;
          }
        }
      }
      // stylelint-enable
    }
  }
}
