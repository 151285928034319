@if $use-ln-password-strength-meter {
  .ln-c-password-strength-meter {
    position: relative;
  }

  .ln-c-password-strength-meter::before,
  .ln-c-password-strength-meter::after {
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
    top: $ln-space;
  }

  .ln-c-password-strength-meter::before {
    background-image: linear-gradient(
      to right,
      ln-get-strength-meter-colours(0)
    );

    // Reason:  Media query targets IE9 only.  IE9 does not support multistop gradients so instead display the background-color
    // stylelint-disable-next-line
    @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      background-color: $ln-color-password-meter-default;
    }
  }

  @for $i from 1 to length($ln-strength-meter-colors) + 1 {
    .ln-c-password-strength-meter.is-level-#{$i}::after {
      background-image: linear-gradient(
        to right,
        ln-get-strength-meter-colours($i)
      );

      // Reason:  Media query targets IE9 only.  IE9 does not support multistop gradients so instead display the background-color
      // stylelint-disable-next-line
      @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
        background-color: nth($ln-strength-meter-colors, $i);
      }
    }
  }

  .ln-c-password-strength-meter__label {
    color: $ln-color-text;
    margin: 0;
    padding-top: $ln-space-x2;
  }

  .ln-c-password-strength-meter__value {
    font-weight: bold;
  }
}
