@if $use-ln-text-input {
  .ln-c-text-input {
    @include ln-text-input;
    @include ln-text-input-validation-states;
  }

  .ln-c-text-input--textarea {
    min-height: 8rem;
    padding-bottom: $ln-space;
    padding-top: $ln-space;
    resize: vertical;
  }

  @include ln-disabled;
}
