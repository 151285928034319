@if $use-ln-field-info {
  .ln-c-form-group.has-error,
  .ln-c-form-group.has-warning {
    display: flex;
    flex-flow: column;
  }

  .ln-c-field-info {
    margin-bottom: $ln-space;
  }

  .ln-c-field-info--error {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: $ln-space;
    order: 1;

    &.ln-c-field-info--validation-first {
      margin-bottom: $ln-space;
      margin-top: 0;
      order: 0;
    }
  }

  .ln-c-field-info--error::before {
    $size: 20px;

    background: $ln-color-warning;
    border-radius: 50%;
    color: $ln-color-white;
    content: '!';
    display: inline-block;
    font-family: $ln-font-family-brand;
    height: $size;
    line-height: $size;
    margin-right: $ln-space;
    text-align: center;
    width: $size;

    .ln-c-form-group.has-error & {
      background: $ln-color-error;
    }
  }
}
