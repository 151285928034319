/// Apply default disabled styling
/// @group mixins
@mixin ln-disabled-style() {
  cursor: default;
  opacity: $ln-opacity-disabled;
}

/// Apply default disabled styling when disabled
/// @group mixins
@mixin ln-disabled() {
  &:disabled,
  &.is-disabled {
    @include ln-disabled-style;
  }
}

/// Apply styling when not disabled
/// @group mixins
/// @content
@mixin ln-not-disabled() {
  &:not(:disabled):not(.is-disabled) {
    @content;
  }
}

/// Apply styling when disabled
/// @group mixins
/// @content
@mixin ln-when-disabled() {
  &:disabled,
  &.is-disabled {
    @content;
  }
}
