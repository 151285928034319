@if $use-ln-progress-spinner {
  @keyframes ln-animation-spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .ln-c-progress-spinner {
    animation: ln-animation-spin 1s infinite linear;
    color: $ln-color-alpha;
    display: inline-block;
    height: $ln-size-def;
    vertical-align: middle;
    width: $ln-size-def;

    &.ln-c-progress-spinner--determinate {
      animation: none;
      transform: rotate(-90deg);
    }

    &.ln-c-progress-spinner--light {
      color: $ln-color-light-highlight;
    }

    &.ln-c-progress-spinner--dark {
      color: $ln-color-dark-highlight;
    }

    &.ln-c-progress-spinner--standalone {
      @include ln-respond-to($ln-size-lg-breakpoint) {
        height: $ln-size-lg;
        width: $ln-size-lg;
      }
    }

    &.ln-c-progress-spinner--icon {
      $size: $ln-icon-space-large * 1px;

      height: $size;
      width: $size;
    }

    &.ln-c-progress-spinner--small {
      $size: $ln-size-def - $ln-space-px * 2;

      height: $size;
      width: $size;
    }

    &.ln-c-progress-spinner--large {
      height: $ln-size-lg;
      width: $ln-size-lg;
    }
  }

  .ln-c-progress-spinner__bg {
    opacity: 0.2;
  }

  .ln-c-progress-spinner__fill {
    transition: stroke-dashoffset $ln-transition-duration ease-in;
  }
}
