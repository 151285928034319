@if $use-ln-flag {
  /*
  The flag object allows you to display textual content.
  and image like content side by side, with the addition
  of being able to vertically align them, the default being
  middle.
  */

  .ln-o-flag:not([class~='ln-o-flag\@']) {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .ln-o-flag__component--top,
  .ln-o-flag__body--top {
    align-self: flex-start;
  }

  .ln-o-flag__component--bottom,
  .ln-o-flag__body--bottom {
    align-self: flex-end;
  }

  .ln-o-flag__component {
    // https://github.com/philipwalton/flexbugs#flexbug-1
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .ln-o-flag__body {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  :not([class~='ln-o-flag\@']) > .ln-o-flag__component--nowrap {
    white-space: nowrap;
  }

  .ln-o-flag__component > img {
    display: block;
    max-width: none;
  }

  @each $key, $value in $ln-breakpoints {
    .ln-o-flag\@#{$key} {
      @include ln-respond-to('max-#{$key}') {
        margin-bottom: -$ln-space;
        margin-top: -$ln-space;
      }

      @include ln-respond-to($key) {
        align-items: center;
        display: flex;
        width: 100%;
      }
    }

    .ln-o-flag\@#{$key} .ln-o-flag__component--nowrap {
      @include ln-respond-to($key) {
        white-space: nowrap;
      }
    }

    .ln-o-flag\@#{$key} .ln-o-flag__component,
    .ln-o-flag\@#{$key} .ln-o-flag__body {
      @include ln-respond-to('max-#{$key}') {
        padding-bottom: $ln-space;
        padding-top: $ln-space;
      }
    }
  }
}
