@function ln-map-set($map, $key, $value) {
  $new: (
    $key: $value,
  );

  @return map-merge($map, $new);
}

@function ln-map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function ln-strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function ln-px-to-rem($target-px) {
  $v: $target-px / $ln-browser-font-size-default-px;

  @return $v * 1rem;
}

@function ln-px-line-height($line-height, $font-size) {
  @return ln-strip-unit($line-height / $font-size);
}

@function ln-fonts-transform($fonts) {
  $result: ();

  @each $type-key, $type-map in $fonts {
    $type-result: ();

    $type-result: ln-map-set(
      $type-result,
      'font-size',
      ln-px-to-rem(ln-map-deep-get($fonts, $type-key, 'font-size'))
    );

    $type-result: ln-map-set(
      $type-result,
      'line-height',
      ln-px-line-height(
        ln-map-deep-get($fonts, $type-key, 'line-height'),
        ln-map-deep-get($fonts, $type-key, 'font-size')
      )
    );

    $result: ln-map-set(
      $result,
      $type-key,
      map-merge(map-get($fonts, $type-key), $type-result)
    );
  }

  @return $result;
}
