.ant-card{
    margin-top: 10px !important;
    border: 0px;
}
.reverse-mapping-title{
    padding-top: 15px !important;
}
.regulation-mapping-cell,
.general-document-mapping-cell,
.product-hierarchy-row{
    font-size: 14px;
}
.ant-card-body {
    padding: 0px 32px !important;
}

#reverse-mapping-hierarchy-selector .ln-c-button{
    min-width: 100px !important;
    margin-left: 40px;
}
#reverse-mapping-hierarchy-selector .button-container > *:last-child {
    float: unset;
}
