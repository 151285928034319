@mixin ln-c-toggle-button-group-theme($theme) {
  // Note: this is modifying another block
  // A bit verbotten in BEM, but necessary
  @include ln-hover-focus-within {
    .ln-c-toggle-button {
      @include ln-not-disabled {
        @include ln-c-toggle-button-hocus-theme($theme);
      }
    }
  }
}

@mixin ln-c-toggle-button-theme($theme) {
  $thin-border: map-get($theme, 'small-border');

  border-color: map-get($theme, 'color-border');
  color: map-get($theme, 'color-text');

  $font: map-get($theme, 'font');

  @if $font {
    @include ln-font($font);
  }

  &:not(.is-active) {
    @include ln-hocus-not-disabled {
      background-color: map-get($theme, 'color-background-highlight');
      border-color: map-get($theme, 'color-border-dark');
      color: map-get($theme, 'color-text-dark');
      z-index: $ln-layer-1;

      @if $thin-border {
        &::before {
          border: 1px solid map-get($theme, 'color-border-dark');
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      } @else {
        &::before {
          display: none;
        }
      }
    }
  }

  &.is-active {
    background-color: map-get($theme, 'color-border');
    color: map-get($theme, 'color-text-on-dark');
  }

  @if $thin-border {
    border-width: $ln-border-size 0 $ln-border-size $ln-border-size;

    &:first-child {
      border-left-width: $ln-border-size;
    }

    &:last-child {
      border-right-width: $ln-border-size;
    }
  } @else {
    border-width: $ln-border-size-large $ln-border-size;

    &:first-child {
      border-left-width: $ln-border-size-large;
    }
    &:last-child {
      border-right-width: $ln-border-size-large;
    }
  }

  // Used for standalone toggle button theming
  @include ln-hocus-not-disabled {
    @include ln-c-toggle-button-hocus-theme($theme);
  }
}

@mixin ln-c-toggle-button-hocus-theme($theme) {
  border-color: map-get($theme, 'color-border-dark');
  color: map-get($theme, 'color-text-dark');

  &.is-active {
    background-color: map-get($theme, 'color-border-dark');
    color: map-get($theme, 'color-text-on-dark');
  }
}

@if $use-ln-toggle-button {
  $theme-default: (
    'color-text': $ln-color-text,
    'color-text-dark': $ln-color-text-active,
    'color-text-on-dark': $ln-color-text-on-beta-active,
    'color-border': $ln-color-base-border,
    'color-border-dark': $ln-color-base-border-active,
    'color-background-highlight': $ln-color-beta-background-highlight,
    'small-border': true,
  );

  $theme-alpha: (
    'font': $ln-font-button,
    'color-text': $ln-color-text-alpha,
    'color-text-dark': $ln-color-text-alpha-active,
    'color-text-on-dark': $ln-color-text-on-alpha-active,
    'color-border': $ln-color-alpha-border,
    'color-border-dark': $ln-color-alpha-border-active,
    'color-background-highlight': $ln-color-alpha-background-highlight,
    'small-border': false,
  );

  .ln-c-toggle-button-group {
    display: flex;

    @include ln-c-toggle-button-group-theme($theme-default);
  }

  .ln-c-toggle-button-group--inline {
    display: inline-flex;
  }

  .ln-c-toggle-button-group--alpha {
    @include ln-c-toggle-button-group-theme($theme-alpha);
  }

  .ln-c-toggle-button {
    @include ln-transition-properties-with-focus(
      (background-color, border-color, color)
    );
    @include ln-disabled;
    @include ln-soft-sides;

    @include ln-not-disabled {
      cursor: pointer;
    }

    align-items: center;
    background-color: $ln-color-transparent;
    border-style: solid;
    display: flex;
    flex: 1 0 auto;
    font-weight: $ln-font-weight-bold;
    height: $ln-size-def;
    justify-content: center;
    position: relative;
    text-decoration: none;

    &:first-child {
      border-bottom-left-radius: $ln-border-radius;
      border-top-left-radius: $ln-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $ln-border-radius;
      border-top-right-radius: $ln-border-radius;
    }

    @include ln-c-toggle-button-theme($theme-default);
  }

  .ln-c-toggle-button--alpha {
    @include ln-c-toggle-button-theme($theme-alpha);
  }
}
