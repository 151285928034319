////
/// Components
/// @group settings/components
////

/// Size of coloured bar used by certain components to denote state
/// @type Length
$ln-status-bar-width: (0.5 * $ln-space-px) !default; // 4px

/// Consistent base border-size for use across all components, can be increased on active states.
/// @type Length
$ln-border-size: 1px !default;

/// Larger border-size used for outlined styles on components.
/// @type Length
$ln-border-size-large: 2px !default;

/// Consistent border-radius for buttons, text-inputs and various other components.
/// @type Length
$ln-border-radius: 2px !default;

/// Consistent transiton durations.
/// @type Time
$ln-transition-duration: 0.2s !default;

/// Transiton duration for longer animations.
/// @type Time
$ln-transition-duration-long: 0.4s !default;

/// With of box shadow for focus ring effect
/// @type Length
$ln-focus-ring-width: 3px !default;

/// Full box shadow property used for focus ring effect
/// @type String
$ln-focus-ring: 0 0 0 $ln-focus-ring-width $ln-color-focus-ring !default;

/// Full box shadow property used as the starting point for focus ring transition
/// @type String
$ln-focus-ring-transparent: 0 0 0 $ln-focus-ring-width transparent !default;

/// Transition timing function used for on focus effects
/// @type String
$ln-focus-transition-timing-function: ease-out !default;

/// Full transition property for focus behaviour, string so can be used as a list in `ln-transitions`
/// @type String
$ln-focus-transition: 'box-shadow #{$ln-transition-duration} #{$ln-focus-transition-timing-function}' !default;

// Tables
$ln-table-cell-label-width: 100px !default;
$ln-table-collapse-map: (
  md: map-get($ln-breakpoints, 'md'),
) !default;

// Icons
$ln-icon-space: $ln-space-value * 0.5;
$ln-icon-space-def: $ln-icon-space * 5;
$ln-icon-space-small: $ln-icon-space * 3;
$ln-icon-space-large: $ln-icon-space * 6;

$ln-icon-size-def: ($ln-icon-space-def / $ln-browser-font-size-default);
$ln-icon-size-small: ($ln-icon-space-small / $ln-browser-font-size-default);
$ln-icon-size-large: ($ln-icon-space-large / $ln-browser-font-size-default);

$ln-icon-font-size-def: 1rem;
$ln-icon-font-size-small: (
    ($ln-icon-space-small / $ln-icon-size-def) / $ln-browser-font-size-default
  ) * 1rem;
$ln-icon-font-size-large: (
    ($ln-icon-space-large / $ln-icon-size-def) / $ln-browser-font-size-default
  ) * 1rem;

$ln-icon-sizes: (
  def: $ln-icon-size-def * 1em,
  small: $ln-icon-size-small * 1em,
  large: $ln-icon-size-large * 1em,
) !default;

$ln-icon-sizes-fixed: (
  def: $ln-icon-size-def * 1rem,
  small: $ln-icon-size-small * 1rem,
  large: $ln-icon-size-large * 1rem,
) !default;

// Input
$ln-color-input-background: $ln-color-white !default;
/// @deprecated
$ln-color-input-border: $ln-color-grey !default;
/// @deprecated
$ln-color-input-border-focus: $ln-color-grey-dark !default;

// Password field strength meter colour
$ln-color-password-meter-default: $ln-color-grey-light !default;

// Accordion
$ln-color-accordion-background: $ln-color-white !default;
$ln-color-accordion-border: $ln-color-base-border !default;

// Badges
$ln-color-badge-primary: $ln-color-alpha !default;
$ln-color-badge-secondary: $ln-color-beta !default;

// Header
$ln-color-header-background: $ln-color-white !default;
$ln-color-header-text: $ln-color-text !default;

// Navigation
$ln-color-navigation-background: $ln-color-white !default;
$ln-navigation-height: $ln-space-px * 8 !default;
$ln-navigation-collapsed-height: $ln-space-px * 7 !default;
$ln-navigation-collapsed-width: 380px !default;
$ln-nav-item-bar-width: $ln-status-bar-width !default;
$ln-navigation-collapsed-indent: $ln-space-x3 !default;
$ln-navigation-collapsed-item-spacing: $ln-space-x2 !default;

// Footer
$ln-color-footer-background: $ln-color-grey-dark !default;
$ln-color-footer-nav-background: $ln-color-footer-background !default;
$ln-color-footer-text: $ln-color-white !default;

/// Status card border variants
/// @type Map
$ln-status-card-variants: (
  red: $ln-color-red,
  amber: $ln-color-yellow,
  green: $ln-color-green,
  blue: $ln-color-blue,
  transparent: $ln-color-transparent,
) !default;

/// Breakpoint at which pagination changes to accommodate larger viewports
/// @type String
$ln-pagination-breakpoint: 'md' !default;

/// Breakpoint at which tabs switch from using arrow navigation to a standard inline tab view
/// @type String
$ln-tabs-breakpoint: 'md' !default;

/// Default maximum width for modals when shown on large screens
/// @type Length
$ln-modal-width: 800px !default;

/// Maximum width for modals with `.ln-c-modal--small` modifier when shown on large screens
/// @type Length
$ln-modal-width-small: 600px !default;
