@if $use-ln-label {
  .ln-c-label {
    @include ln-font-fixed($ln-font-button);

    color: $ln-color-text;
    display: inline-block;
    margin-bottom: $ln-space;
  }

  .ln-c-label__info {
    color: $ln-color-grey;
  }
}
