////
/// @group utilities/border
////

// Disabling stylelint to preserve indentation as sassdoc can't parse indented annotations
// stylelint-disable prettier/prettier
@if $use-ln-border {
/// Adds a border using `$ln-color-base-border`.
/// @name Border
/// @example markup
///   <div class="ln-u-soft ln-u-border">ln-u-border</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-top">ln-u-border-top</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-right">ln-u-border-right</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-bottom">ln-u-border-bottom</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-left">ln-u-border-left</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-sides">ln-u-border-sides</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border-ends">ln-u-border-ends</div>
///
  .ln-u-border {
    border: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-top {
    border-top: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-right {
    border-right: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-bottom {
    border-bottom: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-left {
    border-left: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-sides {
    border-left: $ln-border-size solid $ln-color-base-border !important;
    border-right: $ln-border-size solid $ln-color-base-border !important;
  }

  .ln-u-border-ends {
    border-bottom: $ln-border-size solid $ln-color-base-border !important;
    border-top: $ln-border-size solid $ln-color-base-border !important;
  }
}

@if $use-ln-clean {
/// Removes borders from an element.
/// @name Clean
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean">ln-u-clean</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-top">ln-u-clean-top</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-right">ln-u-clean-right</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-bottom">ln-u-clean-bottom</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-left">ln-u-clean-left</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-sides">ln-u-clean-sides</div>
/// @example markup
///   <div class="ln-u-soft ln-u-border ln-u-clean-ends">ln-u-clean-ends</div>
///
  .ln-u-clean {
    border: 0 !important;
  }

  .ln-u-clean-top {
    border-top: 0 !important;
  }

  .ln-u-clean-right {
    border-right: 0 !important;
  }

  .ln-u-clean-bottom {
    border-bottom: 0 !important;
  }

  .ln-u-clean-sides {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .ln-u-clean-ends {
    border-bottom: 0 !important;
    border-top: 0 !important;
  }
}

@if $use-ln-rounded {
/// Adds border radius to all / left / right / top / bottom
/// @name Rounded
/// @example markup
///   <div class="ln-c-card ln-u-rounded">ln-u-rounded</div>
/// @example markup
///   <div class="ln-c-card ln-u-rounded-left">ln-u-rounded-left</div>
/// @example markup
///   <div class="ln-c-card ln-u-rounded-right">ln-u-rounded-right</div>
/// @example markup
///   <div class="ln-c-card ln-u-rounded-top">ln-u-rounded-top</div>
/// @example markup
///   <div class="ln-c-card ln-u-rounded-bottom">ln-u-rounded-bottom</div>
///
  .ln-u-rounded {
    border-radius: $ln-border-radius !important;
  }

  .ln-u-rounded-left {
    border-bottom-left-radius: $ln-border-radius !important;
    border-top-left-radius: $ln-border-radius !important;
  }

  .ln-u-rounded-right {
    border-bottom-right-radius: $ln-border-radius !important;
    border-top-right-radius: $ln-border-radius !important;
  }

  .ln-u-rounded-top {
    border-top-left-radius: $ln-border-radius !important;
    border-top-right-radius: $ln-border-radius !important;
  }

  .ln-u-rounded-bottom {
    border-bottom-left-radius: $ln-border-radius !important;
    border-bottom-right-radius: $ln-border-radius !important;
  }
}

@if $use-ln-squared {
/// Remove border radius all / left / right / top / bottom
/// @name Squared
/// @example markup
///   <div class="ln-c-button ln-c-button--primary ln-u-squared">ln-u-squared</div>
/// @example markup
///   <div class="ln-c-button ln-c-button--primary ln-u-squared-left">ln-u-squared-left</div>
/// @example markup
///   <div class="ln-c-button ln-c-button--primary ln-u-squared-right">ln-u-squared-right</div>
/// @example markup
///   <div class="ln-c-button ln-c-button--primary ln-u-squared-top">ln-u-squared-top</div>
/// @example markup
///   <div class="ln-c-button ln-c-button--primary ln-u-squared-bottom">ln-u-squared-bottom</div>
///
  .ln-u-squared {
    border-radius: 0 !important;
  }

  .ln-u-squared-left {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .ln-u-squared-right {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .ln-u-squared-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .ln-u-squared-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
