@mixin ln-c-navigation-border($height) {
  // Creates repeating faux border matching the navigation-height.  Makes it flexible to give appropriate repeating borders when the navigation jumps below the title.
  background-color: $ln-color-header-background;
  background-image: repeating-linear-gradient(
    $ln-color-header-background 0,
    $ln-color-header-background ($height - $ln-border-size),
    $ln-color-nav-border ($height - $ln-border-size),
    $ln-color-nav-border $height
  );

  @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    // MQ targets IE9 which does not support repeating gradients.
    border-bottom: $ln-border-size solid $ln-color-nav-border;
  }
}

@if $use-ln-navigation-wrapper {
  .ln-c-navigation-wrapper {
    @include ln-clearfix;
    @include ln-push-left('def', false, true);

    @include ln-respond-to('nav') {
      line-height: $ln-navigation-height;
      margin-left: 0;

      @include ln-c-navigation-border($ln-navigation-height);
    }
  }

  .ln-c-navigation-wrapper__inner {
    @include ln-respond-to('max-nav') {
      clear: left;
      display: flex;
      flex-flow: row wrap;
      max-width: 100%;
      width: $ln-navigation-collapsed-width;
    }
  }

  .ln-c-navigation-wrapper__title {
    color: $ln-color-text-alpha;
    margin-bottom: 0;
    padding-right: $ln-space-x2;
    position: relative;
    white-space: nowrap;
    z-index: $ln-layer-1;

    @include ln-font('display-3');

    @include ln-respond-to('lg') {
      padding-right: $ln-space-x3;
    }

    @include ln-respond-to('max-nav') {
      line-height: $ln-navigation-collapsed-height;
      pointer-events: auto;
      text-indent: $ln-space;

      @include ln-c-navigation-border($ln-navigation-collapsed-height);
    }

    @include ln-respond-to('nav') {
      float: left;
      line-height: $ln-navigation-height;
    }
  }
}
