@if $use-ln-modal {
  $dismiss-pad: $ln-space-half;
  $outer-pad: $ln-space-x2;

  .ln-c-modal {
    align-items: center;
    background-color: $ln-overlay-background;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    padding: $outer-pad;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity $ln-transition-duration;
    width: 100%;
    z-index: $ln-layer-5;

    &.ln-c-modal--full-screen {
      @include ln-respond-to('max-xs') {
        padding: 0;
      }
    }

    &.is-open {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .ln-c-modal__heading {
    max-width: calc(100% - #{$ln-size-def} - #{$dismiss-pad} + #{$outer-pad});
  }

  .ln-c-modal__body {
    background-color: $ln-color-white;
    max-height: calc(100vh - #{$outer-pad * 2});
    max-width: $ln-modal-width;
    opacity: 0;
    outline: none;
    overflow: auto;
    pointer-events: auto;
    position: relative;
    transform: scale(0.5);
    transition: transform $ln-transition-duration,
      opacity $ln-transition-duration;
    visibility: hidden;
    width: 100%;

    @include ln-soft;

    .ln-c-modal--small & {
      max-width: $ln-modal-width-small;
    }

    .ln-c-modal--full-screen & {
      @include ln-respond-to('max-xs') {
        height: 100vh;
        max-height: 100vh;
        width: 100vw;
      }
    }
  }

  .ln-c-modal.is-open .ln-c-modal__body {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
}
