@if $use-ln-search {
  $search-button-width: $ln-space * 6;

  .ln-c-search {
    position: relative;

    @include ln-respond-to('max-nav') {
      background: $ln-color-white;
    }
  }

  .ln-c-search__input {
    padding-right: $search-button-width;
  }

  .ln-c-search--expandable .ln-c-search__input {
    @include ln-respond-to('max-nav') {
      height: $ln-navigation-collapsed-height;
      transform: scale(1, 0);
      transform-origin: 0 0;
    }
  }

  .ln-c-search--expandable.is-open .ln-c-search__input {
    @include ln-respond-to('max-nav') {
      height: $ln-navigation-collapsed-height;
      transform: scale(1);
      transition: transform $ln-transition-duration;
    }
  }

  .ln-c-search--expandable__toggle,
  .ln-c-search__submit {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC4yMzkiIGhlaWdodD0iMzAuMjM5IiB2aWV3Qm94PSIwIDAgMzAuMjM5IDMwLjIzOSI+PHBhdGggZD0iTTIwLjE5NCAzLjQ2Yy00LjYxMy00LjYxMy0xMi4xMi00LjYxMy0xNi43MzQgMC00LjYxMiA0LjYxNC00LjYxMiAxMi4xMiAwIDE2LjczNSA0LjEwOCA0LjEwNyAxMC41MDYgNC41NDcgMTUuMTE2IDEuMzQuMDk3LjQ2LjMyLjg5Ny42NzYgMS4yNTRsNi43MTggNi43MTdjLjk4Ljk3NyAyLjU2Ljk3NyAzLjUzNSAwIC45NzgtLjk3OC45NzgtMi41NiAwLTMuNTM1bC02LjcxOC02LjcyYy0uMzU1LS4zNTQtLjc5NC0uNTc3LTEuMjUzLS42NzQgMy4yMS00LjYxIDIuNzctMTEuMDA4LTEuMzQtMTUuMTE4em0tMi4xMiAxNC42MTRjLTMuNDQ1IDMuNDQ0LTkuMDUgMy40NDQtMTIuNDkzIDAtMy40NC0zLjQ0NC0zLjQ0LTkuMDQ4IDAtMTIuNDkyIDMuNDQ0LTMuNDQzIDkuMDUtMy40NDMgMTIuNDkzIDAgMy40NDQgMy40NDQgMy40NDQgOS4wNDggMCAxMi40OTJ6IiBmaWxsPSIjMTYxNjE2Ii8+PC9zdmc+')
      no-repeat
      center /
      40%;
    color: $ln-color-transparent;
    overflow: hidden;
    position: absolute;
    width: $search-button-width;

    &:hover,
    &:focus {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC4yMzkiIGhlaWdodD0iMzAuMjM5IiB2aWV3Qm94PSIwIDAgMzAuMjM5IDMwLjIzOSI+PHBhdGggZD0iTTIwLjE5NCAzLjQ2Yy00LjYxMy00LjYxMy0xMi4xMi00LjYxMy0xNi43MzQgMC00LjYxMiA0LjYxNC00LjYxMiAxMi4xMiAwIDE2LjczNSA0LjEwOCA0LjEwNyAxMC41MDYgNC41NDcgMTUuMTE2IDEuMzQuMDk3LjQ2LjMyLjg5Ny42NzYgMS4yNTRsNi43MTggNi43MTdjLjk4Ljk3NyAyLjU2Ljk3NyAzLjUzNSAwIC45NzgtLjk3OC45NzgtMi41NiAwLTMuNTM1bC02LjcxOC02LjcyYy0uMzU1LS4zNTQtLjc5NC0uNTc3LTEuMjUzLS42NzQgMy4yMS00LjYxIDIuNzctMTEuMDA4LTEuMzQtMTUuMTE4em0tMi4xMiAxNC42MTRjLTMuNDQ1IDMuNDQ0LTkuMDUgMy40NDQtMTIuNDkzIDAtMy40NC0zLjQ0NC0zLjQ0LTkuMDQ4IDAtMTIuNDkyIDMuNDQ0LTMuNDQzIDkuMDUtMy40NDMgMTIuNDkzIDAgMy40NDQgMy40NDQgMy40NDQgOS4wNDggMCAxMi40OTJ6IiBmaWxsPSIjZjA2YzAwIi8+PC9zdmc+');
    }
  }

  .ln-c-search__submit {
    bottom: 1px;
    right: 1px;
    top: 1px;
  }

  .ln-c-search--expandable {
    @include ln-respond-to('max-nav') {
      height: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: $ln-layer-2;
    }
  }

  .ln-c-search--expandable.is-open {
    @include ln-respond-to('max-nav') {
      border-bottom: 1px solid $ln-color-nav-border;
      height: $ln-navigation-collapsed-height;
      transition: height $ln-transition-duration;
      z-index: $ln-layer-3;
    }
  }

  .ln-c-search:not(.ln-c-search--expandable) {
    @include ln-respond-to('max-nav') {
      border: 1px solid $ln-color-nav-border;
      height: $ln-navigation-collapsed-height;
      margin: $ln-navigation-collapsed-indent;
    }
  }

  .ln-c-search--expandable__toggle {
    background-color: $ln-color-white;
    border-bottom-width: 1px;
    border-color: $ln-color-nav-border;
    border-left-width: 1px;
    border-style: solid;
    bottom: 100%;
    height: $ln-navigation-collapsed-height;
    pointer-events: all;
    position: absolute;
    right: 0;
    width: $search-button-width;

    @include ln-respond-to('nav') {
      display: none;
    }

    @media all and (min-width: 0\0) {
      // IE9 prevent doubled up borders.
      border-bottom-width: 0;
    }
  }

  .ln-c-search--expandable.is-open .ln-c-search--expandable__toggle {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8dGl0bGU+Q2xvc2U8L3RpdGxlPg0KICAgIDxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6IiBmaWxsPSIjZjA2YzAwIiAvPg0KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4NCjwvc3ZnPg==');
    background-size: 50%;
  }
}
