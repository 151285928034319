$ln-assets-url: "~luna-style";
@import "luna-react/scss/style";
@import "./css/user-defined.scss";

:focus {
  box-shadow: 0 0 0 0px !important;
}

label {
  font-size: 1.125rem !important;
}

a:focus {
  text-decoration: none !important;
}

.toaster-success {
  background-color: $ln-color-green !important;
  color: $ln-color-white !important;
  text-align: center;
}

.Toastify__close-button--default {
  color: $ln-color-white !important;
  opacity: 0.7 !important;
}

.toaster-error {
  background-color: $ln-color-red !important;
  color: $ln-color-white !important;
  text-align: center;
}

.toaster-info {
  background-color: $ln-color-yellow !important;
  color: $ln-color-grey-dark !important;
  text-align: center;
}

.luna-table-edit-row {
  background-color: $ln-color-grey !important;
  color: $ln-color-white !important;
}

.luna-table-edit-row .ln-c-button {
  color: $ln-color-white !important;
}

.luna-table-inactive-user-row {
  color: $ud-inactive-colour !important;
}

.luna-table-inactive-user-row .ln-c-button {
  color: $ud-inactive-colour !important;
}

.remove-header-underline:hover {
  text-decoration: none !important;
}

.grid-header-container {
  display: flex;
}

.progress-indicator {
  flex-grow: 10;
  margin-right: $ln-space-x3;
}

.grid-header-container > .button-group {
  margin-left: auto;
}

#remove-item{
  margin-right: 2rem;
  padding-top: 2px !important;
}

.page-size-block{
  float: right;
  margin-top: 1rem;
}

.page-size-dropdown{
  max-width: 120px;
}

.page-size-label{
  line-height: 2.33333 !important;
}

.grid-header-container > div:last-child {
  margin-right: 0;
}

.grid-header-container h2 {
  margin: 0px !important;
  padding-right: $ln-space-x3;
}

.loading-notification {
  position: static;
  z-index: 2;
}


.ln-c-table-container::-webkit-scrollbar-thumb {
  background-color: darkgrey!important;
  outline: 5px solid slategrey!important;
}

.ln-c-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.table-max-width{
  max-width: 240px;
  overflow-wrap: break-word;
}
.action-button{
  min-width: 100% !important;
}

.table-header-action{
  text-align: center !important;
}