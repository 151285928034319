@if $use-ln-form-group {
  .ln-c-form-group {
    margin-bottom: $ln-space-x3;
    position: relative;
  }

  .ln-c-form-group--with-separator {
    margin-bottom: $ln-space-x4;
  }
}
