html {
  font-size: 100%;
  min-height: 100%;
}

body {
  background: $ln-color-site-background;
  color: $ln-color-text;

  @include ln-font($ln-font-body);
}

a {
  @include ln-transition-properties-with-focus(color);

  color: $ln-color-links;

  &:hover,
  &:focus {
    color: $ln-color-text-alpha-active;
  }
}

hr {
  // Set hr styling to match site-wide border styles.
  border: 0;
  border-bottom: $ln-border-size solid $ln-color-base-border;
}

/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
table,
legend,
figure,
pre,
hr {
  @include ln-push-bottom;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount.
 */
ul,
ol,
dd {
  margin-left: $ln-space-x2;
}

/* Not supported by Autoprefixer */
/* stylelint-disable selector-no-vendor-prefix */
::-webkit-input-placeholder {
  color: $ln-color-text-placeholder;
}

::-moz-placeholder {
  color: $ln-color-text-placeholder;
}

:-ms-input-placeholder {
  color: $ln-color-text-placeholder;
}
/* stylelint-enable selector-no-vendor-prefix */

::placeholder {
  color: $ln-color-text-placeholder;
}

/**
 * Custom focus ring
 */
a:focus,
button:focus,
[tabindex]:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

a,
button,
[tabindex] {
  &:focus {
    box-shadow: $ln-focus-ring;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  // Requires qualifier to work with polyfill
  /* stylelint-disable selector-no-qualifying-type */
  .js-focus-visible & {
    &:focus {
      box-shadow: none;
    }

    &.focus-visible {
      box-shadow: $ln-focus-ring;
    }
  }
  // stylelint-enable
}
