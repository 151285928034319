@if $use-ln-progress-indicator {
  .ln-c-progress-indicator {
    align-items: center;
    // Only receives temporary focus while visable so disable focus ring
    box-shadow: none !important;
    display: flex;
    flex-wrap: wrap;

    &.is-loading {
      width: 100%;
    }

    &.ln-c-progress-indicator--page {
      flex-direction: column;
    }

    &.ln-c-progress-indicator--page {
      align-items: center;
      background-color: $ln-overlay-background;
      bottom: 0;
      color: $ln-color-text-light;
      display: flex;
      justify-content: center;
      left: 0;
      opacity: 0;
      -webkit-overflow-scrolling: touch;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity $ln-transition-duration;
      width: 100%;
      z-index: $ln-layer-5;

      &.is-loading {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .ln-c-progress-indicator__message {
    flex-grow: 1;

    .ln-c-progress-indicator--spaced & {
      @include ln-push-left('sm');
    }

    .ln-c-progress-indicator--page & {
      @include ln-font('display-1');

      flex-grow: 0;
      margin-top: $ln-space;
    }
  }
}
