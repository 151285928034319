@import '../vendor/airbnb-react-dates';

@if $use-ln-date-picker {
  $item-size: 38px;
  $border-radius: $ln-border-radius;
  $offset-fix: 10px;
  $nav-inset: 23px;

  // Note: important to keep up-to-date with latest airbnb library code
  @include ln-vendor-airbnb-react-dates;

  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    z-index: $ln-layer-3;
  }

  // It should be possible to set the noBorder prop but
  // currently this seems to subtly break styling
  .SingleDatePickerInput__withBorder,
  .DateRangePickerInput__withBorder {
    border-color: transparent;
  }

  .DateInput_input {
    @include ln-text-input;
    @include ln-text-input-validation-states;

    font-family: inherit;
  }

  .DateInput_fangStroke {
    fill: $ln-color-white;
    stroke: $ln-color-grey-light;
  }

  .CalendarMonth_caption {
    @include ln-font('display-1');

    text-align: left;
  }

  .CalendarMonthGrid__horizontal {
    left: $offset-fix;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: $offset-fix;
  }

  .CalendarDay {
    @include ln-font('caption');

    color: inherit;
  }

  .CalendarDay__default {
    border-radius: $border-radius;
    border-width: 0;

    @include ln-hocus {
      background-color: $ln-color-base-background-highlight-opaque;
      border-color: $ln-color-base-background-highlight-opaque;
      border-width: 0;
      color: $ln-color-text-active;
      position: relative;
    }
  }

  .CalendarDay__selected {
    background-color: $ln-color-alpha-background;
    border-color: $ln-color-alpha-background;
    color: $ln-color-text-on-alpha;
    font-weight: $ln-font-weight-bold;

    @include ln-hocus {
      background-color: $ln-color-alpha-background-active;
      border-color: $ln-color-alpha-background-active;
      color: $ln-color-text-on-alpha-active;
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span:focus {
    background-color: $ln-color-alpha-background-highlight-opaque;
    border-color: $ln-color-alpha-background-highlight-opaque;
    border-radius: 0;
  }

  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_span:focus {
    background-color: $ln-color-alpha-background-active;
    border-color: $ln-color-alpha-background-active;
    color: $ln-color-text-on-alpha-active;
  }

  .CalendarDay__selected_start {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: 0;
  }

  .CalendarDay__selected_end {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius;
  }

  .CalendarDay__blocked_out_of_range {
    color: $ln-color-grey-light;

    @include ln-hocus {
      background-color: $ln-color-transparent;
      color: $ln-color-grey-light;
    }
  }

  .DayPicker_weekHeader {
    color: $ln-color-grey;
    top: 54px;
  }

  .DayPicker_weekHeader_li {
    height: $item-size;
    line-height: $item-size;
    text-transform: uppercase;
  }

  .CalendarMonth_caption {
    // Magic-ish numbers to open up calendar spacing
    // to more closely align with Luna design
    padding-bottom: 48px;
    padding-top: 24px;
  }

  .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 0 1px;
    // Hack to fix adding border-spacing between rows
    // I.e. ~numOfRows * 1px
    margin-top: -4px;
  }

  .DayPicker__withBorder {
    border-radius: 0;
    box-shadow: 0 0 0 1px $ln-color-grey-light;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right-color: $ln-color-base-background;

    @include ln-hocus {
      border-right-color: $ln-color-base-background-active;
    }
  }

  .ln-c-date-picker__nav-prev,
  .ln-c-date-picker__nav-next {
    align-items: center;
    border-radius: $border-radius;
    display: flex;
    height: $item-size;
    justify-content: center;
    position: absolute;
    top: 18px;
    width: $item-size;

    @include ln-hocus {
      background-color: $ln-color-base-background-highlight-opaque;
    }

    @include ln-transition-properties-with-focus((background-color));
  }

  .ln-c-date-picker__nav-prev {
    right: $nav-inset + $item-size;
  }

  .ln-c-date-picker__nav-next {
    right: $nav-inset;
  }
}
