@function ln-text-input-inset-box-shadow($color) {
  @return inset 0 0 0 1px $color;
}

@mixin ln-text-input-focus-box-shadow($color) {
  @include ln-focus-focus-within {
    border-color: $color;
    box-shadow: ln-text-input-inset-box-shadow($color), $ln-focus-ring;
  }
}

@mixin ln-text-input-border-variant($color) {
  border-color: $color;
  border-left-width: $ln-status-bar-width;

  &:hover {
    box-shadow: ln-text-input-inset-box-shadow($color),
      $ln-focus-ring-transparent;
  }

  @include ln-text-input-focus-box-shadow($color);
}

@mixin ln-text-input {
  @include ln-transition-properties-with-focus(border-color);

  background-color: $ln-color-input-background;
  // White linear-gradient to override iOS default gradient
  background-image: linear-gradient(
    $ln-color-input-background,
    $ln-color-input-background
  );
  border: $ln-border-size solid $ln-color-base-border;
  border-radius: $ln-border-radius;
  color: $ln-color-text;
  min-height: $ln-size-def;
  padding: 0 $ln-space-x2;
  width: 100%;

  &:not(:disabled):not([readonly]) {
    @include ln-hover-focus-focus-within {
      color: $ln-color-text-active;
    }

    &:hover {
      border-color: $ln-color-base-border-active;
      box-shadow: ln-text-input-inset-box-shadow($ln-color-base-border-active);
    }

    @include ln-text-input-focus-box-shadow($ln-color-alpha-border-active);
  }
}

@mixin ln-text-input-validation-states {
  &:not(:disabled):not([readonly]) {
    .ln-c-form-group.has-error & {
      @include ln-text-input-border-variant($ln-color-error);
    }

    .ln-c-form-group.has-warning & {
      @include ln-text-input-border-variant($ln-color-warning);
    }
  }
}
