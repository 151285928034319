@if $use-ln-dropdown-list {
  .ln-c-dropdown-list {
    background-color: $ln-color-white;
    border-color: $ln-color-base-border;
    border-style: none solid solid;
    border-width: $ln-border-size;
    list-style: none;
    margin: 0;
    max-height: $ln-size-def * 5;
    overflow: auto;

    &:empty {
      border-style: none;
    }
  }

  .ln-c-dropdown-list--overlay {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: $ln-layer-3;
  }

  .ln-c-dropdown-list__item {
    min-height: $ln-size-def;
    padding: $ln-space $ln-space-x2;

    &:not(.ln-c-dropdown-list__item--message) {
      &:focus,
      &:hover,
      &.is-hover,
      &.is-selected {
        background-color: $ln-color-beta-background-highlight;
        color: $ln-color-text-active;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .ln-c-dropdown-list__item--align-center {
    align-items: center;
    display: flex;
  }
}
