@if $use-ln-article {
  .ln-o-article {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hgroup,
    ul,
    ol,
    dl,
    blockquote,
    p,
    address,
    table,
    legend,
    figure,
    pre,
    hr {
      margin-top: 0.75em;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      max-width: 45rem;
    }
  }
}
