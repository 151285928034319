/// Combines multiple transition values into a single property including an override for
/// `prefers-reduced-motion` users
/// @group mixins
/// @param {List} $transition - List of full `transition` properties
@mixin ln-transitions($transitions) {
  $all-transitions: ();

  @for $i from 1 through length($transitions) {
    $all-transitions: append(
      $all-transitions,
      unquote(nth($transitions, $i)),
      comma
    );
  }

  transition: $all-transitions;

  // stylelint-disable-next-line media-feature-name-no-unknown
  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

/// Uses `ln-transitions` to only apply `$ln-transition-focus`
/// @group mixins
@mixin ln-focus-transition() {
  box-shadow: $ln-focus-ring-transparent;

  @include ln-transitions(($ln-focus-transition));
}

/// Makes use of `ln-transitions` to combine requested transitions with focus effect
/// @group mixins
/// @param {List} $transitions - List of full `transition` properties to add to focus transtion
@mixin ln-transitions-with-focus($transitions) {
  box-shadow: $ln-focus-ring-transparent;

  @include ln-transitions(
    append($ln-focus-transition, nth($transitions, $i), comma)
  );
}

/// Similar to `ln-transitions` but used when the effect should mimic the timingfunction
/// used by the focus behaviour (defined by `$ln-focus-transition-timing-function`)
/// @group mixins
/// @param {List} $properties - List of CSS properties that should transition
@mixin ln-transition-properties-with-focus($properties) {
  $all-properties: (box-shadow);

  @for $i from 1 through length($properties) {
    $all-properties: append($all-properties, nth($properties, $i), comma);
  }

  box-shadow: $ln-focus-ring-transparent;
  transition-duration: $ln-transition-duration;
  transition-property: $all-properties;
  transition-timing-function: $ln-focus-transition-timing-function;

  // stylelint-disable-next-line media-feature-name-no-unknown
  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
