// Disabling stylelint to preserve indentation as sassdoc can't parse indented annotations
// stylelint-disable prettier/prettier
@if $use-ln-hidden {
/// Adds `display: none;` to an element so it is hidden for all users
/// @name Hidden
/// @group utilities/display
/// @example markup
///   <div class="ln-u-hidden">I'm hidden</div>
///
  .ln-u-hidden {
    display: none !important;
  }
}

@if $use-ln-visually-hidden {
/// Visually hides an element whilst ensuring the content will still be read out by screen readers.
/// Also has the option to only hide up to a given breakpoint.
/// @name Visually hidden
/// @group utilities/display
/// @example markup
///   <div class="ln-u-visually-hidden">Only screen readers will get this</div>
///   <div class="ln-u-visually-hidden@max-sm">Visually hidden until "sm" breakpoint</div>
///
  .ln-u-visually-hidden {
    @include ln-visually-hidden(true);
  }

  .ln-u-visually-hidden-focusable {
    @include ln-visually-hidden(true);

    &:focus {
      @include ln-visually-hidden(true, true);
    }
  }

  @each $key, $value in $ln-breakpoints {
    .ln-u-visually-hidden\@max-#{$key} {
      @include ln-respond-to('max-#{$key}') {
        $important: true;

        @include ln-visually-hidden($important);
      }
    }
  }
}

@if $use-ln-circle {
/// Adds `border-radius` to a square element to make circular in shape
/// @name Circle
/// @group utilities/misc
/// @example markup
///   <div class="ln-u-display-inline-block ln-u-soft ln-u-bg-color-orange ln-u-circle"></div>
///
  .ln-u-circle {
    border-radius: 1000px !important;
  }
}

@if $use-ln-ellipsis {
/// Adds ellipsis to highlight that text content has been cut off due to the constraints of the container
/// @name Ellipsis
/// @group utilities/misc
/// @example markup
///   <div class="ln-u-ellipsis">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eu diam quis turpis egestas elementum.</div>
///
  .ln-u-ellipsis {
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

@if $use-ln-fill-height {
/// Expands the element to the full height of it's container
/// @name Fill height
/// @group utilities/misc
/// @example markup
///   <div class="ln-u-fill-height"></div>
///
  .ln-u-fill-height {
    height: 100%;
  }
}
