@if $use-ln-flyout {
  .ln-o-flyout {
    overflow: hidden;
    position: relative;
  }

  .ln-o-flyout__content {
    @include ln-visually-hidden;

    left: 0;
    top: 100%;
    z-index: $ln-layer-3;
  }

  .ln-o-flyout__content--right {
    left: auto;
    right: 0;
  }

  .ln-o-flyout__content--top {
    bottom: 100%;
    top: auto;
  }

  .ln-o-flyout:hover,
  .ln-o-flyout.is-open {
    overflow: visible;
  }

  .ln-o-flyout:hover > .ln-o-flyout__content,
  .ln-o-flyout.is-open > .ln-o-flyout__content {
    /**
     * Undo the properties set from `ln-visually-hidden` mixin.
     **/

    @include ln-visually-hidden(false, true);

    position: absolute;
  }
}
