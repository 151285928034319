@if $use-ln-avatar {
  .ln-c-avatar {
    $size: 35px;

    border-radius: 50%;
    height: $size;
    vertical-align: middle;
    width: $size;

    @include ln-respond-to('max-nav') {
      $size: 30px;

      height: $size;
      width: $size;
    }
  }
}
