@mixin ln-c-badge-variant($name, $background-color, $color: $ln-color-white) {
  &--#{$name} {
    background-color: $background-color;
    color: $color;
  }

  &--#{$name}.ln-c-badge--inverted {
    background-color: $color;
    color: $background-color;
  }
}

@if $use-ln-badge {
  .ln-c-badge {
    display: inline-block;
    font-family: $ln-font-family-base;
    font-size: 0.8em; // cosmetic sizing
    font-weight: $ln-font-weight-bold;
    line-height: normal;
    min-width: 1.5em; // cosmetic: roughly square as font scales
    padding: 0.15em 0.25em; // cosmetic sizing
    text-align: center;

    @include ln-c-badge-variant('primary', $ln-color-badge-primary);
    @include ln-c-badge-variant('secondary', $ln-color-badge-secondary);
  }
}
