@if $use-ln-icon-nav {
  .ln-c-icon-nav {
    @include ln-clearfix;

    list-style: none;
    margin: 0;
    white-space: nowrap;

    @include ln-respond-to('nav') {
      float: right;
      padding-left: $ln-space;
      text-align: center;
    }

    @include ln-respond-to('max-nav') {
      flex: 1 100%;
      order: 2;
    }
  }

  .ln-c-icon-nav__item {
    position: relative;

    @include ln-respond-to('max-nav') {
      background-color: $ln-color-white;
    }

    @include ln-respond-to('nav') {
      float: left;
      height: $ln-navigation-height;
    }
  }

  .ln-c-icon-nav__item:not(:only-child) {
    @include ln-respond-to('nav') {
      border-left: $ln-border-size solid $ln-color-nav-border;
    }
  }

  .ln-c-icon-nav__item:not(.ln-c-icon-nav__item--fixed) {
    @include ln-respond-to('max-nav') {
      backface-visibility: hidden;
      transform: translate(-100%, 0);
    }
  }

  .ln-c-icon-nav__item:not(.ln-c-icon-nav__item--fixed) .ln-c-icon-nav__link {
    @include ln-respond-to('max-nav') {
      padding: $ln-navigation-collapsed-item-spacing
        $ln-navigation-collapsed-indent;
    }
  }

  .ln-c-header.is-open .ln-c-icon-nav__item:not(.ln-c-icon-nav__item--fixed) {
    @include ln-respond-to('max-nav') {
      transform: translate(0, 0);
      transition: transform $ln-transition-duration;
    }
  }

  .ln-c-icon-nav__item--fixed {
    @include ln-respond-to('max-nav') {
      border-left: $ln-border-size solid $ln-color-nav-border;
      height: $ln-navigation-collapsed-height;
      left: 0;
      line-height: $ln-navigation-collapsed-height;
      pointer-events: auto;
      position: absolute;
      right: 0;
      text-indent: 0;
      top: 0;
      z-index: initial;
    }
  }

  .ln-c-icon-nav__item--fixed .ln-c-search:not(.ln-c-search--expandable) {
    border-left: $ln-border-size solid $ln-color-nav-border;
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
  }

  .ln-c-icon-nav__item--fixed .ln-c-icon-nav__link {
    @include ln-respond-to('max-nav') {
      background: $ln-color-white;
      border-bottom: $ln-border-size solid $ln-color-nav-border;
      border-left: $ln-border-size solid $ln-color-nav-border;
      padding-left: $ln-space;
      position: absolute;
      right: 0;

      &::before {
        display: none;
      }

      &::after {
        @include ln-font($ln-font-button);

        font-weight: $ln-font-weight-medium;
        padding-left: $ln-space;
        padding-right: $ln-space;
      }
    }
  }

  .ln-c-icon-nav__link {
    display: block;
    font-size: 0;
    height: 100%;
    position: relative;
    text-decoration: none;

    @include ln-respond-to('nav') {
      width: $ln-navigation-height;
    }

    @include ln-respond-to('max-nav') {
      &::before {
        background: $ln-color-alpha;
        bottom: 2px;
        content: ' ';
        left: 0;
        position: absolute;
        top: 2px;
        transform: scale(0);
        width: $ln-nav-item-bar-width;
      }

      &::after {
        @include ln-font($ln-font-button);

        content: attr(title);
        font-weight: $ln-font-weight-medium;
        padding-left: $ln-space-x2;
        padding-right: $ln-space-x2;
        vertical-align: middle;
      }
    }
  }

  .ln-c-icon-nav__link .ln-c-icon {
    font-size: $ln-icon-font-size-large;
  }

  .ln-c-icon-nav__item--fixed .ln-c-icon {
    @include ln-respond-to('max-nav') {
      font-size: $ln-icon-font-size-def;
    }
  }

  .ln-c-icon-nav__link--avatar {
    font-size: 0;
  }

  .ln-c-icon-nav__link:hover::before,
  .ln-c-icon-nav__link:focus::before,
  .ln-c-icon-nav__link.is-active::before {
    transform: scale(1);
  }

  .ln-c-icon-nav__form {
    display: block;
    height: 100%;
  }

  .ln-c-icon-nav__item--fixed .ln-c-icon-nav__form:not(.is-open) {
    @include ln-respond-to('max-nav') {
      pointer-events: none;
    }
  }

  .ln-c-icon-nav__item:only-child .ln-c-icon-nav__form {
    @include ln-respond-to('nav') {
      border-left: $ln-border-size solid $ln-color-nav-border;
    }
  }

  .ln-c-icon-nav__text-input,
  .ln-c-text-input.ln-c-icon-nav__text-input {
    background: $ln-color-transparent;
    border: 0;
    font-weight: normal;
    height: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
}
