@if $use-ln-topbar {
  .ln-c-topbar {
    background-color: $ln-color-header-background;
    white-space: nowrap;

    @include ln-respond-to('max-nav') {
      backface-visibility: hidden;
      left: 0;
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 100%;
      transform: translate(-100%, 0);
      width: $ln-navigation-collapsed-width;

      @include ln-soft-top;

      &:not(.ln-c-topbar--hide-divider)::before {
        background: $ln-color-nav-border;
        content: ' ';
        height: $ln-border-size;
        left: $ln-navigation-collapsed-indent;
        position: absolute;
        right: $ln-navigation-collapsed-indent;
        top: ln-map-deep-get($ln-scaled-space, 'def', 'def') * 0.5;
      }
    }

    @include ln-respond-to('nav') {
      border-bottom: $ln-border-size solid $ln-color-nav-border;
      padding: $ln-space 0;
    }
  }

  .ln-c-topbar .ln-o-container {
    @include ln-respond-to('max-nav') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include ln-respond-to('max-nav') {
    .ln-c-header.is-open .ln-c-topbar {
      transform: translate(0, 0);
      transition: transform $ln-transition-duration;
    }

    .ln-c-topbar .ln-o-flag,
    .ln-c-topbar .ln-o-flag__body,
    .ln-c-topbar .ln-o-flag__component {
      display: block;
    }

    .ln-c-topbar__item--logo {
      display: none;
    }

    .ln-c-topbar__text {
      padding: $ln-navigation-collapsed-item-spacing
        $ln-navigation-collapsed-indent;
      white-space: initial;
    }
  }

  .ln-c-topbar__list {
    list-style: none;
    margin: 0;
  }

  @include ln-respond-to('nav') {
    .ln-c-topbar__item {
      display: inline-block;
      vertical-align: middle;
    }

    .ln-c-topbar__item + .ln-c-topbar__item {
      margin-left: $ln-space-x2;
    }
  }

  .ln-c-topbar__text {
    display: block;
  }
}
