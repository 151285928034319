h1,
.ln-u-h1 {
  @include ln-font($ln-font-h1);
}

h2,
.ln-u-h2 {
  @include ln-font($ln-font-h2);
}

h3,
.ln-u-h3 {
  @include ln-font($ln-font-h3);
}

h4,
.ln-u-h4 {
  @include ln-font($ln-font-h4);
}

h5,
.ln-u-h5 {
  @include ln-font($ln-font-h5);
}

h6,
.ln-u-h6 {
  @include ln-font($ln-font-h6);
}
