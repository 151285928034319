.modal {
  font-size: large;
  text-align: center;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
  min-height: 100px;
  overflow-wrap: break-word;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: x-large;
  text-align: center;
  padding: 5px;
}
.popup-content{
  min-width:30% !important;
  padding: 20px !important;
  
} 
