@if $use-ln-standalone-link {
  /// @deprecated Use .ln-c-link.ln-c-link--cta
  .ln-c-standalone-link {
    @include ln-font($ln-font-button);
    @include ln-transition-properties-with-focus((border-color, color));

    color: currentColor;
    position: relative;
    text-decoration: none;

    &::after {
      background-color: $ln-color-text;
      bottom: 0;
      content: '';
      height: $ln-border-size-large;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transition-duration: $ln-transition-duration;
      transition-property: background-color, transform;
    }

    &:hover,
    &:focus {
      color: $ln-color-text-alpha-active;

      &::after {
        background-color: $ln-color-text-alpha-active;
        transform: scaleX(1);
      }
    }
  }

  .ln-c-standalone-link--underlined {
    &::after {
      transform: scaleX(1);
    }
  }

  .ln-c-standalone-link--alpha {
    color: $ln-color-text-alpha;

    &::after {
      background-color: $ln-color-text-alpha;
    }
  }

  .ln-c-standalone-link--beta {
    color: $ln-color-text-beta;

    &::after {
      background-color: $ln-color-text-beta;
    }

    &:hover,
    &:focus {
      color: $ln-color-text-beta-active;

      &::after {
        background-color: $ln-color-text-beta-active;
      }
    }
  }

  .ln-c-standalone-link--light {
    color: $ln-color-text-light;

    &::after {
      background-color: $ln-color-text-light;
    }

    &:hover,
    &:focus {
      color: $ln-color-text-light-active;

      &::after {
        background-color: $ln-color-text-light-active;
      }
    }
  }

  .ln-c-standalone-link--dark {
    color: $ln-color-text-dark;

    &::after {
      background-color: $ln-color-text-dark;
    }

    &:hover,
    &:focus {
      color: $ln-color-text-dark-active;

      &::after {
        background-color: $ln-color-text-dark-active;
      }
    }
  }
}
