////
/// Colours theming
/// @group settings/colours/theming
////
$ln-color-base-light: $ln-color-grey-light !default;
$ln-color-base: $ln-color-grey !default;
$ln-color-base-dark: $ln-color-grey-dark !default;

$ln-color-alpha-light: $ln-color-orange-light !default;
$ln-color-alpha: $ln-color-orange !default;
$ln-color-alpha-dark: $ln-color-orange-dark !default;

$ln-color-beta-light: $ln-color-grey-light !default;
$ln-color-beta: $ln-color-grey !default;
$ln-color-beta-dark: $ln-color-grey-dark !default;

$ln-color-dark: $ln-color-grey-dark !default;
$ln-color-dark-highlight: $ln-color-black !default;

$ln-color-light: $ln-color-grey-v-light !default;
$ln-color-light-highlight: $ln-color-white !default;

$ln-colors-theming: (
  'alpha-light': $ln-color-alpha-light,
  'alpha': $ln-color-alpha,
  'alpha-dark': $ln-color-alpha-dark,
  'beta-light': $ln-color-beta-light,
  'beta': $ln-color-beta,
  'beta-dark': $ln-color-beta-dark,
  'dark': $ln-color-dark,
  'dark-highlight': $ln-color-dark-highlight,
  'light': $ln-color-light,
  'light-highlight': $ln-color-light-highlight,
) !default;

// Typography

/// Base text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text: $ln-color-grey-dark !default;

/// Text colour when displayed on the base surface colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-base: $ln-color-white !default;

/// @deprecated - Use `$ln-color-text`
$ln-color-base-text: $ln-color-text !default;

/// Empasis / active text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-active: $ln-color-black !default;

/// Base text colour used by CTA controls
/// @group settings/colours/typography
/// @type Color
$ln-color-text-cta: $ln-color-black !default;

/// Text colour used by elements that have placeholder text
/// @group settings/colours/typography
/// @type Color
$ln-color-text-placeholder: $ln-color-grey !default;

/// Primary brand text colour in default state
/// @group settings/colours/typography
/// @type Color
$ln-color-text-alpha: $ln-color-alpha !default;

/// Primary brand text colour in active state
/// @group settings/colours/typography
/// @type Color
$ln-color-text-alpha-active: $ln-color-alpha-dark !default;

/// Text colour when displayed on primary brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-alpha: $ln-color-white !default;

/// Text colour when displayed on active primary brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-alpha-active: $ln-color-text-on-alpha !default;

/// Secondary brand text colour in default state
/// @group settings/colours/typography
/// @type Color
$ln-color-text-beta: $ln-color-beta !default;

/// Secondary brand text colour in active state
/// @group settings/colours/typography
/// @type Color
$ln-color-text-beta-active: $ln-color-beta-dark !default;

/// Text colour when displayed on secondary brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-beta: $ln-color-white !default;

/// Text colour when displayed on active secondary brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-beta-active: $ln-color-text-on-beta !default;

/// Dark brand text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-dark: $ln-color-text !default;

/// Dark brand highlight text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-dark-active: $ln-color-text-active !default;

/// Text colour when displayed on dark brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-dark: $ln-color-white !default;

/// Text colour when displayed on active dark brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-dark-active: $ln-color-text-on-dark !default;

/// Light brand text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-light: $ln-color-light !default;

/// Light brand highlight text colour
/// @group settings/colours/typography
/// @type Color
$ln-color-text-light-active: $ln-color-light-highlight !default;

/// Text colour when displayed on light brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-light: $ln-color-text !default;

/// Text colour when displayed on highlight light brand color
/// @group settings/colours/typography
/// @type Color
$ln-color-text-on-light-active: $ln-color-text-active !default;

/// Base link colour
/// @group settings/colours/typography
/// @type Color
$ln-color-links: currentColor !default;

// General

/// Site background colour
/// @group settings/colours/general
/// @type Color
$ln-color-site-background: $ln-color-grey-v-light !default;

/// Base background colours
/// @group settings/colours/backgrounds
/// @type Color
$ln-color-base-background: $ln-color-base !default;
$ln-color-base-background-active: $ln-color-base-dark !default;
$ln-color-base-background-highlight: rgba(
  $ln-color-base-dark,
  $ln-opacity-background-highlight
) !default;
$ln-color-base-background-highlight-opaque: mix(
  $ln-color-base-dark,
  $ln-color-white,
  $ln-opacity-background-highlight * 100%
) !default;

/// Base border colours
/// @group settings/colours/borders
/// @type Color
$ln-color-base-border: $ln-color-base !default;
$ln-color-base-border-active: $ln-color-base-dark !default;

/// Primary brand background colours
/// @group settings/colours/backgrounds
/// @type Color
$ln-color-alpha-background: $ln-color-alpha !default;
$ln-color-alpha-background-active: $ln-color-alpha-dark !default;
$ln-color-alpha-background-highlight: rgba(
  $ln-color-alpha-dark,
  $ln-opacity-background-highlight
) !default;
$ln-color-alpha-background-highlight-opaque: mix(
  $ln-color-alpha-dark,
  $ln-color-white,
  $ln-opacity-background-highlight * 100%
) !default;

/// Primary brand border colours
/// @group settings/colours/borders
/// @type Color
$ln-color-alpha-border: $ln-color-alpha !default;
$ln-color-alpha-border-active: $ln-color-alpha-dark !default;

/// Secondary brand background colours
/// @group settings/colours/backgrounds
/// @type Color
$ln-color-beta-background: $ln-color-beta !default;
$ln-color-beta-background-active: $ln-color-beta-dark !default;
$ln-color-beta-background-highlight: rgba(
  $ln-color-beta-dark,
  $ln-opacity-background-highlight
) !default;

/// Secondary brand border colours
/// @group settings/colours/borders
/// @type Color
$ln-color-beta-border: $ln-color-beta !default;
$ln-color-beta-border-active: $ln-color-beta-dark !default;

/// Dark brand background colours
/// @group settings/colours/backgrounds
/// @type Color
$ln-color-dark-background: $ln-color-dark !default;
$ln-color-dark-background-active: $ln-color-dark-highlight !default;
$ln-color-dark-background-highlight: rgba(
  $ln-color-dark-highlight,
  $ln-opacity-background-highlight
) !default;

/// Dark brand border colours
/// @group settings/colours/borders
/// @type Color
$ln-color-dark-border: $ln-color-dark !default;
$ln-color-dark-border-active: $ln-color-dark-highlight !default;

/// Light brand background colours
/// @group settings/colours/backgrounds
/// @type Color
$ln-color-light-background: $ln-color-light !default;
$ln-color-light-background-active: $ln-color-light-highlight !default;
$ln-color-light-background-highlight: rgba(
  $ln-color-light-highlight,
  $ln-opacity-background-highlight + 0.02
) !default;

/// Light brand border colours
/// @group settings/colours/borders
/// @type Color
$ln-color-light-border: $ln-color-light !default;
$ln-color-light-border-active: $ln-color-light-highlight !default;

/// Standard nav border colour
/// @group settings/colours/general
/// @type Color
$ln-color-nav-border: $ln-color-base-border !default;

/// Error colour
/// @group settings/colours/general
/// @type Color
$ln-color-error: $ln-color-red !default;

/// Warning colour
/// @group settings/colours/general
/// @type Color
$ln-color-warning: $ln-color-yellow !default;

/// Disabled colour
/// @group settings/colours/general
/// @type Color
$ln-color-disabled: $ln-color-grey !default;

/// Custom keyboard focus ring colour
/// @group settings/colours/general
/// @type Color
$ln-color-focus-ring: rgba($ln-color-aqua, 0.5) !default;

/// Highlight warning background color
/// @group settings/colours/highlight
/// @type Color
$ln-color-warning-background-highlight: rgba(
  $ln-color-warning,
  $ln-opacity-background-highlight
) !default;

/// Highlight error background color
/// @group settings/colours/highlight
/// @type Color
$ln-color-error-background-highlight: rgba(
  $ln-color-error,
  $ln-opacity-background-highlight
) !default;

// Overlay background
$ln-color-overlay: $ln-color-black !default;
$ln-overlay-background: rgba($ln-color-overlay, 0.7) !default;
