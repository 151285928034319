////
/// @group utilities/layout
////

// Disabling stylelint to preserve indentation as sassdoc can't parse indented annotations
// stylelint-disable prettier/prettier
@if $use-ln-display {
/// Offers all `display:` properties as a standard utility class and also responsive variant
/// @name Display
/// @example markup
///   <div class="ln-u-display-none ln-u-display-block@md">
///     Hidden for small divices but visible on medium screens
///   </div>
///
  $display-values: none, inline, inline-block, block, table, table-cell,
    table-row, flex, inline-flex;

  @each $display-value in $display-values {
    .ln-u-display-#{$display-value} {
      display: $display-value !important;
    }
  }

  @if $use-ln-display-responsive {
    @each $display-value in $display-values {
      @each $key, $value in $ln-breakpoints {
        .ln-u-display-#{$display-value}\@#{$key} {
          @include ln-respond-to('min-#{$key}') {
            display: $display-value !important;
          }
        }
      }
    }
  }
}

@if $use-ln-center-block {
/// Centers the element by adding `auto` to the horizontal margins
/// @name Center block
/// @example markup
///   <div class="ln-u-center-block">Center block</div>
///
  .ln-u-center-block {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
