// Mary Ann Regular
@font-face {
  font-family: MaryAnn;
  font-style: normal;
  font-weight: 400;
  src: url('#{$ln-assets-url}/fonts/mary_ann-regular.woff2'),
    url('#{$ln-assets-url}/fonts/mary_ann-regular.woff');
}

// Mary Ann Medium
@font-face {
  font-family: MaryAnn;
  font-style: normal;
  font-weight: 500;
  src: url('#{$ln-assets-url}/fonts/mary_ann-medium.woff2'),
    url('#{$ln-assets-url}/fonts/mary_ann-medium.woff');
}

// Mary Ann Extrabold
@font-face {
  font-family: MaryAnn;
  font-style: normal;
  font-weight: 700;
  src: url('#{$ln-assets-url}/fonts/mary_ann-extrabold.woff2'),
    url('#{$ln-assets-url}/fonts/mary_ann-extrabold.woff');
}
