@if $use-ln-breadcrumbs {
  $crumb-spacing: $ln-space-x3;
  $divider-size: $ln-space-x2;

  .ln-c-breadcrumbs {
    margin-left: -$crumb-spacing;

    @include ln-push-bottom;
  }

  .ln-c-breadcrumbs__item {
    padding-left: $crumb-spacing;
    position: relative;

    &:not(.ln-c-breadcrumbs__item--active) {
      &::after {
        background-image: url('data:image/svg+xml;base64, PHN2ZyByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNOC4yOTI4OTMyMiwxOC4yOTI4OTMyIEM3LjkwMjM2ODkzLDE4LjY4MzQxNzUgNy45MDIzNjg5MywxOS4zMTY1ODI1IDguMjkyODkzMjIsMTkuNzA3MTA2OCBDOC42ODM0MTc1MSwyMC4wOTc2MzExIDkuMzE2NTgyNDksMjAuMDk3NjMxMSA5LjcwNzEwNjc4LDE5LjcwNzEwNjggTDE2LjcwNzEwNjgsMTIuNzA3MTA2OCBDMTcuMDk3NjMxMSwxMi4zMTY1ODI1IDE3LjA5NzYzMTEsMTEuNjgzNDE3NSAxNi43MDcxMDY4LDExLjI5Mjg5MzIgTDkuNzA3MTA2NzgsNC4yOTI4OTMyMiBDOS4zMTY1ODI0OSwzLjkwMjM2ODkzIDguNjgzNDE3NTEsMy45MDIzNjg5MyA4LjI5Mjg5MzIyLDQuMjkyODkzMjIgQzcuOTAyMzY4OTMsNC42ODM0MTc1MSA3LjkwMjM2ODkzLDUuMzE2NTgyNDkgOC4yOTI4OTMyMiw1LjcwNzEwNjc4IEwxNC41ODU3ODY0LDEyIEw4LjI5Mjg5MzIyLDE4LjI5Mjg5MzIgWiIgZmlsbD0iIzRjNGM0YyIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: $divider-size;
        line-height: 1rem;
        position: absolute;
        right: ($divider-size + ($crumb-spacing - $divider-size) / 2) * -1;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: $ln-space-x2;
      }

      .ln-c-breadcrumbs--light &::after {
        background-image: url('data:image/svg+xml;base64, PHN2ZyByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNOC4yOTI4OTMyMiwxOC4yOTI4OTMyIEM3LjkwMjM2ODkzLDE4LjY4MzQxNzUgNy45MDIzNjg5MywxOS4zMTY1ODI1IDguMjkyODkzMjIsMTkuNzA3MTA2OCBDOC42ODM0MTc1MSwyMC4wOTc2MzExIDkuMzE2NTgyNDksMjAuMDk3NjMxMSA5LjcwNzEwNjc4LDE5LjcwNzEwNjggTDE2LjcwNzEwNjgsMTIuNzA3MTA2OCBDMTcuMDk3NjMxMSwxMi4zMTY1ODI1IDE3LjA5NzYzMTEsMTEuNjgzNDE3NSAxNi43MDcxMDY4LDExLjI5Mjg5MzIgTDkuNzA3MTA2NzgsNC4yOTI4OTMyMiBDOS4zMTY1ODI0OSwzLjkwMjM2ODkzIDguNjgzNDE3NTEsMy45MDIzNjg5MyA4LjI5Mjg5MzIyLDQuMjkyODkzMjIgQzcuOTAyMzY4OTMsNC42ODM0MTc1MSA3LjkwMjM2ODkzLDUuMzE2NTgyNDkgOC4yOTI4OTMyMiw1LjcwNzEwNjc4IEwxNC41ODU3ODY0LDEyIEw4LjI5Mjg5MzIyLDE4LjI5Mjg5MzIgWiIgZmlsbD0iI2Y2ZjZmNiIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPgo=');
      }
    }
  }

  .ln-c-breadcrumbs__link {
    color: $ln-color-text;
    display: inline;
    text-decoration: none;

    @include ln-hocus {
      border-bottom: $ln-border-size solid currentColor;
      color: $ln-color-text-active;
    }

    .ln-c-breadcrumbs__item--active & {
      color: $ln-color-text-active;
      font-weight: $ln-font-weight-bold;

      @include ln-hocus {
        border-bottom: none;
      }
    }

    .ln-c-breadcrumbs--light & {
      color: $ln-color-text-light;

      @include ln-hocus {
        color: $ln-color-text-light-active;
      }

      .ln-c-breadcrumbs__item--active & {
        color: $ln-color-text-light-active;
      }
    }
  }

  @include ln-respond-to('max-sm') {
    .ln-c-breadcrumbs--narrow {
      margin-left: 0;
    }

    .ln-c-breadcrumbs--narrow > .ln-c-breadcrumbs__item {
      &:not(:nth-last-child(2)) {
        padding-left: 0;

        &::after {
          display: none;
        }

        .ln-c-breadcrumbs__link {
          @include ln-visually-hidden;

          &:focus {
            @include ln-visually-hidden(false, true);

            margin-right: $crumb-spacing;
          }
        }
      }

      &:nth-last-child(2) {
        margin-right: $crumb-spacing;

        &::after {
          left: ($crumb-spacing - $divider-size) / 2;
          right: auto;
          transform: translateY(-50%) rotate(-180deg);
        }

        .ln-c-breadcrumbs__link::before {
          content: 'Back to ';
        }
      }
    }
  }
}
