////
/// Globals
/// @group settings/globals
////
$ln-browser-font-size-default: 16;
$ln-browser-font-size-default-px: $ln-browser-font-size-default * 1px;

$ln-space-value: 8 !default;
$ln-space-px: $ln-space-value * 1px !default;

/// Default spacing value used throughout Luna, on the grid, spaced lists, utilities.
/// Has calculated variants for `-half` and `-x2` to `-x5`.
/// @type Length
$ln-space: ($ln-space-value / $ln-browser-font-size-default) * 1rem !default;
$ln-space-half: ($ln-space * 0.5);
$ln-space-x2: ($ln-space * 2);
$ln-space-x3: ($ln-space * 3);
$ln-space-x4: ($ln-space * 4);
$ln-space-x5: ($ln-space * 5);
$ln-space-x6: ($ln-space * 6);
$ln-space-x7: ($ln-space * 7);
$ln-space-x8: ($ln-space * 8);

/// Map of fixed spacing values used by classes to iterate over and create
/// multiplier variants.
/// @type Map
/// @prop {Length} ln-space value [multiplier]
$ln-fixed-space: (
  def: $ln-space,
  '*1/2': $ln-space-half,
  '*2': $ln-space-x2,
  '*3': $ln-space-x3,
  '*4': $ln-space-x4,
) !default;

/// Map of fixed spacing values used by classes to iterate over and create
/// multiplier variants.
/// @type Map
/// @prop {Map} ln-space at breakpoint value [size key]
$ln-scaled-space: (
  def: (
    def: $ln-space-x2,
    sm: $ln-space-x3,
  ),
  sm: (
    def: $ln-space,
    sm: $ln-space-x2,
  ),
  lg: (
    def: $ln-space-x3,
    sm: $ln-space-x4,
  ),
  xl: (
    def: $ln-space-x4,
    sm: $ln-space-x5,
  ),
) !default;

/// All of the media query breakpoints specified by Luna. Many components /
/// objects such as the grid & flag will iterate over this map during the Sass compilation.
/// Consumers can add additional properties to the map.
/// @type Map
/// @prop {Length} breakpoint [width]
$ln-breakpoints: (
  xs: 480px,
  ss: 600px,
  sm: 720px,
  md: 960px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1800px,
) !default;

/// Screen size at which the header will change layout
/// @type Length
$ln-nav-breakpoint-size: map-get($ln-breakpoints, 'sm') !default;

@if $ln-nav-breakpoint-size != false {
  $ln-breakpoints: map-merge(
    $ln-breakpoints,
    (
      nav: $ln-nav-breakpoint-size,
    )
  );
}

/// A string that is used for loading Luna assets such as the Mary Ann fonts. By default it's value is assets. Consumers will likely have to change this variable to match the path to the assets they import from Luna.
/// @type String
$ln-assets-url: 'assets' !default;

/// Default minimum height / width used by components
/// @type Length
$ln-size-def: (6 * $ln-space-px) !default; // 48px

/// Height / width used by `--shallow` / `--sm` modifiers to display a smaller variant of a component
/// @type Length
$ln-size-sm: (5 * $ln-space-px) !default; // 40px

/// Height / width used by `--deep` / `--lg` modifiers to display a larger variant of a component
/// @type Length
$ln-size-lg: (10 * $ln-space-px) !default; // 80px

/// Named breakpoint at which `$ln-size-lg` should take effect on components
/// @type Breakpoint
$ln-size-lg-breakpoint: 'min-md' !default;

/// A Sass map that contains percentages that are used to set the base-font-size in REM on the root
/// HTML node. This influences the computed value of `ln-spacing-unit` ultimately resulting in it
/// decreasing on smaller viewports at set breakpoints.
/// @type Map
/// @prop {Number} breakpoint [percentage]
/// @deprecated
$ln-spacing-percentages: (
  def: 100%,
) !default;

/// Initial raised z-index layer, used by focus states
/// @type Number
$ln-layer-1: 1 !default;

/// z-index layer used by absolutely positioned elements overlaying other elements
/// @type Number
$ln-layer-2: 2 !default;

/// z-index layer used by dropdowns / overlays
/// @type Number
$ln-layer-3: 3 !default;

/// z-index layer used by fixed elements, ones with expandable menus e.g. header
/// @type Number
$ln-layer-4: 4 !default;

/// Uppermost z-index layer displaying above all others, used by modals
/// @type Number
$ln-layer-5: 5 !default;

/// Opacity used when an element is in a disabled state
/// @type Number
$ln-opacity-disabled: 0.35 !default;

/// Opacity used on backgrounds for hover/focus highlight
/// @type Number
$ln-opacity-background-highlight: 0.08 !default;

/// Opacity used for full screen tint effects when actions are blocked
/// @type Number
$ln-opacity-overlay: 0.7;

/// A more subtle opacity variant of `$ln-opacity-overlay` used for less impactful events
/// @type Number
$ln-opacity-overlay-light: 0.15;

/// @deprecated - Use $ln-space
$ln-spacing-unit: 1rem;
$ln-spacing-unit-quarter: ($ln-spacing-unit * 0.25);
$ln-spacing-unit-half: ($ln-spacing-unit * 0.5);
$ln-spacing-unit-double: ($ln-spacing-unit * 2);
$ln-spacing-unit-x2: ($ln-spacing-unit * 2);
$ln-spacing-unit-x3: ($ln-spacing-unit * 3);
$ln-spacing-unit-x4: ($ln-spacing-unit * 4);
$ln-spacing-unit-x5: ($ln-spacing-unit * 5);
