@if $use-ln-activities {
  $space-between: $ln-space;

  .ln-c-activities {
    bottom: $space-between;
    display: none;
    list-style: none;
    margin-bottom: -$space-between;
    margin-left: 0;
    position: fixed;
    right: $space-between;
    z-index: $ln-layer-3;

    @include ln-respond-to('xs') {
      width: 25rem;
    }

    @include ln-respond-to('max-xs') {
      left: $space-between;
    }
  }

  .ln-c-activities.is-open {
    display: block;
  }

  .ln-c-activities__item {
    background-color: $ln-color-white;
    box-shadow: 0 1px 4px 0 rgba($ln-color-black, 0.1);
    margin-bottom: $space-between;
    position: relative;
  }

  .ln-c-activities__item--notification {
    display: none;
    padding-right: $ln-size-def;

    &.is-open {
      display: block;
    }
  }

  .ln-c-activities__item:not(.ln-c-activities__item--notification) {
    @include ln-soft;
  }
}
