////
/// Objects
/// @group settings/objects
////

/// A Sass map containing the varying gutter sizes to be compiled for the grid. The keys in the map
/// directly correlate to the class name used. Fore example to target the zero gutter width
/// `<div class="ln-o-grid ln-grid--gutter-zero />` the `zero` portion maps to the key in
/// `$ln-grid-gutter-sizes` consumers can add additional properties to the map.
/// @type Map
/// @prop {Length} breakpoint [size]
$ln-grid-gutter-sizes: map-merge(
  $ln-scaled-space,
  (
    zero: 0,
  )
);

/// A Sass map containing spacing sizes made available for `<ul class="ln-o-[type]-list">`.
/// @type Map
/// @prop {Length} breakpoint [size]
$ln-list-spacing: $ln-scaled-space !default;

/// Determines max-width options available on the container.
/// 'def' must be provided but other options can be overriden.
///
/// @type Map
///
/// @example scss Override defaults
/// $ln-container-sizes: (
///   def: 1280,
///   tiny: 640
/// );
$ln-container-sizes: (
  def: 1440,
  xs: 800,
  sm: 1280,
  lg: 1800,
) !default;

/// Scaled space options available on the container
///
/// @type Map
$ln-container-soft-variants: $ln-scaled-space !default;

/// Scaled bottom padding options available on the section
///
/// @type Map
$ln-section-soft-variants: (
  def: map-get($ln-scaled-space, 'lg'),
  xs: map-get($ln-scaled-space, 'sm'),
  sm: map-get($ln-scaled-space, 'def'),
  lg: map-get($ln-scaled-space, 'xl'),
) !default;

/// @deprecated - Use container instead
///
/// Maximum width of the object `<div class="ln-o-site-wrap" />` that typically wraps content on a page
/// @type Length
$ln-site-wrap-max-width: (1440 / $ln-browser-font-size-default) * 1rem !default;

/// Maximum width of the object & modifier `<div class="ln-o-site-wrap ln-o-site-wrap--squash" />`
/// wrapping page content
/// @type Length
$ln-site-wrap-squeeze-max-width: (1248 / $ln-browser-font-size-default) * 1rem !default;

/// Maximum width of the object & modifier `<div class="ln-o-site-wrap ln-o-site-wrap--large" />`
/// wrapping page content
/// @type Length
$ln-site-wrap-large-max-width: (1800 / $ln-browser-font-size-default) * 1rem !default;

$ln-site-wrap-padding-variants: $ln-scaled-space !default;
