/* stylelint-disable max-nesting-depth, comment-empty-line-before */
@if $use-ln-pagination {
  $change-breakpoint: $ln-pagination-breakpoint;
  $change-breakpoint-max: 'max-#{$change-breakpoint}';
  $disabled-opacity: 0.35;
  $border-radius: $ln-border-radius;
  $list-padding-default: 0 ($ln-size-def + (2 * $ln-space-px));
  $list-padding-with-first-last: 0 ((2 * $ln-size-def) + (2 * $ln-space-px));
  $size: $ln-size-def;

  .ln-c-pagination__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    min-height: $size;
    padding: $list-padding-default;
    position: relative;

    /*
      Prevent accidental text selection when using the page nav links.
      Reset in info.
    */
    user-select: none;
  }

  /* Size to fit content if full width modifier not applied */
  .ln-c-pagination:not(.ln-c-pagination--full) {
    .ln-c-pagination__list {
      @include ln-respond-to($change-breakpoint) {
        display: inline-flex;
      }
    }
  }

  /* Show first / last */
  .ln-c-pagination--first-last {
    .ln-c-pagination__list {
      padding: $list-padding-with-first-last;
    }

    &.ln-c-pagination--pages {
      .ln-c-pagination__list {
        @include ln-respond-to($change-breakpoint) {
          padding: $list-padding-default;
        }
      }
    }
  }

  /* Hide first / last when showing page links on wide screens */
  @include ln-respond-to($change-breakpoint) {
    .ln-c-pagination--first-last.ln-c-pagination--pages {
      .ln-c-pagination__list {
        padding: $list-padding-default;

        .ln-c-pagination__item--previous {
          left: 0;
        }

        .ln-c-pagination__item--next {
          right: 0;
        }
      }
    }
  }

  .ln-c-pagination__item {
    min-width: $size;
    text-align: center;
  }

  .ln-c-pagination__item--page,
  .ln-c-pagination__item--ellipsis {
    display: none;

    /* Only display page items at certain breakpoint */
    @include ln-respond-to($change-breakpoint) {
      display: list-item;
    }
  }

  .ln-c-pagination__item--previous {
    left: 0;
    position: absolute;
    top: 0;

    .ln-c-pagination--first-last & {
      left: $size;
    }
  }

  .ln-c-pagination__item--next {
    position: absolute;
    right: 0;
    top: 0;

    .ln-c-pagination--first-last & {
      right: $size;
    }
  }

  .ln-c-pagination__item--first {
    left: 0;
    position: absolute;
    top: 0;
  }

  .ln-c-pagination__item--last {
    position: absolute;
    right: 0;
    top: 0;
  }

  /* Fix radius based on block modifiers */
  .ln-c-pagination--first-last.ln-c-pagination--pages {
    @include ln-respond-to($change-breakpoint) {
      .ln-c-pagination__item--first,
      .ln-c-pagination__item--last {
        display: none;
      }
    }
  }

  .ln-c-pagination__link {
    @include ln-transition-properties-with-focus(
      (opacity, background-color, border-color, color)
    );

    @include ln-hocus {
      color: $ln-color-text-active;
    }

    align-items: center;
    background-color: $ln-color-transparent;
    border-radius: $border-radius;
    color: $ln-color-text;
    display: flex;
    font-weight: $ln-font-weight-bold;
    height: $size;
    justify-content: center;
    min-width: $size;
    opacity: 1;
    position: relative;
    text-decoration: none;

    @include ln-not-disabled {
      @include ln-hocus {
        background-color: $ln-color-base-background-highlight;
        z-index: $ln-layer-1;

        &:not(.is-current)::before {
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: $ln-layer-1;
        }
      }
    }

    @include ln-disabled;
  }

  .ln-c-pagination__item {
    &.is-current {
      .ln-c-pagination__link {
        background-color: $ln-color-beta;
        color: $ln-color-text-on-beta;

        @include ln-hocus {
          color: $ln-color-text-on-beta;
        }
      }
    }
  }

  .ln-c-pagination__list {
    @include ln-hover-focus-within {
      .ln-c-pagination__link {
        color: $ln-color-text-active;
      }

      .ln-c-pagination__item.is-current .ln-c-pagination__link {
        background-color: $ln-color-beta-background-active;
        color: $ln-color-text-on-beta-active;
      }
    }
  }

  .ln-c-pagination__info {
    align-items: center;
    display: flex;
    font-weight: $ln-font-weight-bold;
    min-height: $size;
    user-select: text;

    /*
      Workaround for IE 11 flex, min-height, align-items center bug
      https://github.com/philipwalton/flexbugs/issues/231#issuecomment-362790042
    */
    &::after {
      content: '';
      display: block;
      font-size: 0;
      min-height: inherit;
    }

    .ln-c-pagination--pages & {
      @include ln-respond-to($change-breakpoint) {
        @include ln-visually-hidden;
      }
    }
  }
}
