@if $use-ln-matrix-list {
  .ln-o-matrix-list {
    list-style: none;
  }

  .ln-o-matrix-list__item {
    display: inline-block;
    vertical-align: middle;
  }

  @include ln-list-spacing('matrix-list', 'left');
  @include ln-list-spacing('matrix-list', 'bottom');
}
