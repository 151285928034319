@if $use-ln-status-card {
  .ln-c-status-card {
    background-color: $ln-color-white;
    border-left: $ln-status-bar-width solid $ln-color-grey;
    display: block;

    @include ln-soft-ends;
  }

  @each $variant, $color in $ln-status-card-variants {
    .ln-c-status-card--#{$variant} {
      border-left-color: $color;
    }
  }

  .ln-c-status-card--link {
    @include ln-transition-properties-with-focus((color, border));

    box-shadow: inset 0 0 0 1px transparent, $ln-focus-ring-transparent;
    color: inherit;
    font-weight: inherit;
    padding-right: $ln-space-x3;
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
      color: inherit;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $ln-color-base-border;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px $ln-color-base-border, $ln-focus-ring;
    }
  }

  .ln-c-status-card--link:hover .ln-c-status-card__chevron,
  .ln-c-status-card--link:focus .ln-c-status-card__chevron {
    color: $ln-color-text-alpha-active;
  }

  .ln-c-status-card__chevron {
    position: absolute;
    right: $ln-space;
    top: 50%;
    transform: translateY(-50%);
    transition: color $ln-transition-duration;
  }

  .ln-c-status-card__content {
    @include ln-soft-sides;
  }

  .ln-c-status-card__chevron .ln-c-icon {
    display: block;
  }
}
