////
/// @group settings/typography
////

/// The default font-family that gets applied to the `<body />`
/// @type String
$ln-font-family-base: arial, sans-serif !default;

/// Brand font that gets applied to headings and other branded text.
/// @type String
$ln-font-family-brand: MaryAnn, 'Trebuchet MS', sans-serif !default;

/// Default font weight
/// @type Number
$ln-font-weight-regular: 400 !default;

/// Medium font weight
/// @type Number
$ln-font-weight-medium: 500 !default;

/// Bold font weight
/// @type Number
$ln-font-weight-bold: 700 !default;

/// A Sass map containing the font styles of the Luna type stack
/// @type Map
$ln-fonts: (
  display-7: (
    font-size: 62px,
    line-height: 76px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-6: (
    font-size: 50px,
    line-height: 62px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-5: (
    font-size: 40px,
    line-height: 50px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-4: (
    font-size: 32px,
    line-height: 40px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-3: (
    font-size: 26px,
    line-height: 32px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-2: (
    font-size: 22px,
    line-height: 26px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  display-1: (
    font-size: 20px,
    line-height: 26px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  button: (
    font-size: 18px,
    line-height: 24px,
    font-family: $ln-font-family-brand,
    font-weight: $ln-font-weight-bold,
  ),
  body-2: (
    font-size: 18px,
    line-height: 26px,
    font-family: $ln-font-family-base,
    font-weight: $ln-font-weight-regular,
  ),
  body-1: (
    font-size: 16px,
    line-height: 24px,
    font-family: $ln-font-family-base,
    font-weight: $ln-font-weight-regular,
  ),
  caption: (
    font-size: 14px,
    line-height: 20px,
    font-family: $ln-font-family-base,
    font-weight: $ln-font-weight-regular,
  ),
) !default;

$ln-fonts-transformed: ln-fonts-transform($ln-fonts);

/// Controls how each type stack option in `$ln-fonts` scales once the breakpoint defined
/// in $ln-fonts-scaled-breakpoint is reached. Specifies the new type stack key that will be used
/// @type Map
$ln-fonts-scaled: (
  display-6: 'display-7',
  display-5: 'display-6',
  display-4: 'display-5',
  display-3: 'display-4',
  display-2: 'display-3',
  display-1: 'display-2',
) !default;

/// Specifies at which breakpoint the larger type stack keys in `$ln-fonts-scaled` will be applied
/// @type String
$ln-fonts-scaled-breakpoint: 'min-sm' !default;

/// Which type stack key in `$ln-fonts` should be used for h1 styling
/// @type String
$ln-font-h1: 'display-5' !default;

/// Which type stack key in `$ln-fonts` should be used for h2 styling
/// @type String
$ln-font-h2: 'display-4' !default;

/// Which type stack key in `$ln-fonts` should be used for h3 styling
/// @type String
$ln-font-h3: 'display-3' !default;

/// Which type stack key in `$ln-fonts` should be used for h4 styling
/// @type String
$ln-font-h4: 'display-2' !default;

/// Which type stack key in `$ln-fonts` should be used for h5 styling
/// @type String
$ln-font-h5: 'display-1' !default;

/// Which type stack key in `$ln-fonts` should be used for h6 styling
/// @type String
$ln-font-h6: 'display-1' !default;

/// Which type stack key in `$ln-fonts` should be used for body text styling
/// @type String
$ln-font-body: 'body-1' !default;

/// Which type stack key in `$ln-fonts` should be used for button / cta styling
/// @type String
$ln-font-button: 'button' !default;

// @deprecated
$ln-font-hero: 'display-6' !default;

// @deprecated
$ln-font-body-sm: 'caption' !default;

@if ($use-ln-fonts-small) {
  $ln-font-h1: 'display-4';
  $ln-font-h2: 'display-3';
  $ln-font-h3: 'display-2';
  $ln-font-h4: 'display-1';
  $ln-font-h5: 'display-1';
  $ln-font-h6: 'display-1';
}

/// Uses keys to map element / usage references in font mixins to the relevant var
/// @type Map
$ln-font-lookup: (
  h1: $ln-font-h1,
  h2: $ln-font-h2,
  h3: $ln-font-h3,
  h4: $ln-font-h4,
  h5: $ln-font-h5,
  h6: $ln-font-h6,
  body: $ln-font-body,
  // @deprecated
    // Use new font key references, e.g. `caption` instead of `body-sm`
    hero: $ln-font-hero,
  body-sm: $ln-font-body-sm,
  cta: $ln-font-button,
);
