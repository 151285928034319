@if $use-ln-inline-list {
  .ln-o-inline-list {
    list-style: none;
    margin: 0;
  }

  .ln-o-inline-list__item {
    display: inline-block;
    vertical-align: middle;
  }

  @include ln-list-spacing('inline-list', 'left');
}
