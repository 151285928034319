@if $use-ln-autocomplete {
  $border-width: $ln-border-size;
  $selected-item-height: $ln-space-px * 4;

  .ln-c-autocomplete {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include ln-text-input;
    @include ln-text-input-validation-states;
  }

  .ln-c-autocomplete.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ln-c-autocomplete__input {
    align-self: stretch;
    background-color: $ln-color-transparent;
    border: none;
    box-shadow: none;
    color: $ln-color-text;
    flex-grow: 1;
    min-height: $ln-size-def - ($border-width * 2);
    min-width: 10rem;
    padding: 0;

    &:hover,
    &:focus {
      color: $ln-color-text-active;
    }
  }

  .ln-c-autocomplete__selected-item {
    align-items: center;
    background-color: $ln-color-grey-v-light;
    border: $ln-border-size solid $ln-color-base-border;
    display: inline-flex;
    line-height: 1;
    margin: $ln-space-half $ln-space $ln-space-half 0;
    min-height: $selected-item-height;
    padding: $ln-space-half $ln-space;
  }

  .ln-c-autocomplete__clear-selected-item {
    background-color: $ln-color-transparent;
    background-image: none;
    line-height: 1;
    margin-left: $ln-space-half;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
  }
}
