////
/// Colours (immutable palette)
/// @group settings/colours/base
////
$ln-color-orange-light: #f90;

/// Orange
/// @type Color
$ln-color-orange: #f06c00;

/// Orange dark
/// @type Color
$ln-color-orange-dark: #e55000;

$ln-color-plum-light: #a50052;

/// Plum
/// @type Color
$ln-color-plum: #7f0442;

$ln-color-plum-dark: #57002b;

/// White
/// @type Color
$ln-color-white: #fff;

/// Grey very light
/// @type Color
$ln-color-grey-v-light: #f6f6f6;

/// Grey light
/// @type Color
$ln-color-grey-light: #d7d7d7;

/// Grey
/// @type Color
$ln-color-grey: #8e8e8e;

/// Grey dark
/// @type Color
$ln-color-grey-dark: #4c4c4c;

$ln-color-grey-v-dark: #242424;

/// Black
/// @type Color
$ln-color-black: #0c0c0c;

/// Red
/// @type Color
$ln-color-red: #d70000;

/// Aqua
/// @type Color
$ln-color-aqua: #00a9cf;

$ln-color-blue: #427fed;

/// Green
/// @type Color
$ln-color-green: #218234;

/// Yellow
/// @type Color
$ln-color-yellow: #ffc911;

/// Transparent
/// @type Color
$ln-color-transparent: transparent;

$ln-colors: (
  'orange': $ln-color-orange,
  'orange-dark': $ln-color-orange-dark,
  'plum': $ln-color-plum,
  'white': $ln-color-white,
  'grey-v-light': $ln-color-grey-v-light,
  'grey-light': $ln-color-grey-light,
  'grey': $ln-color-grey,
  'grey-dark': $ln-color-grey-dark,
  'black': $ln-color-black,
  'red': $ln-color-red,
  'aqua': $ln-color-aqua,
  'green': $ln-color-green,
  'yellow': $ln-color-yellow,
  'transparent': $ln-color-transparent,
);
