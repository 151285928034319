@if $use-ln-page {
  .ln-o-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .ln-o-page__body {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .ln-o-page__header,
  .ln-o-page__footer {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .ln-o-page--sticky-header {
    .ln-o-page__header {
      top: -$ln-navigation-height;
      transition: top $ln-transition-duration-long;
      width: 100%;
      z-index: $ln-layer-3;
    }

    &.ln-o-page--is-sticky {
      &.ln-o-page--custom-header {
        padding-top: $ln-navigation-height;
      }

      &:not(.ln-o-page--custom-header) {
        @include ln-respond-to('nav') {
          padding-top: $ln-navigation-height;
        }
      }

      .ln-o-page__header {
        position: fixed;
        top: 0;
      }
    }
  }

  // IE10 + fix for min-height on flex https://github.com/philipwalton/flexbugs#flexbug-3
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ln-o-page:not(.ln-o-page--sticky-header) {
      @include ln-respond-to('nav') {
        height: 100vh;
        overflow: auto;
      }
    }
  }
}
