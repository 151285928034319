@if $use-ln-navigation {
  $dropdown-icon-size: $ln-icon-space-def * 1px;

  .ln-c-navigation {
    list-style: none;
    margin: 0;

    @include ln-respond-to('max-nav') {
      backface-visibility: hidden;
      background: $ln-color-navigation-background;
      flex: 1 100%;
      order: 1;
      position: relative;
      transform: translate(-100%, 0);
    }

    @include ln-respond-to('max-xs') {
      width: 100%;
    }

    .ln-c-header.is-open & {
      @include ln-respond-to('max-nav') {
        transform: translate(0, 0);
        transition: transform $ln-transition-duration;
      }
    }
  }

  @include ln-respond-to('nav') {
    .ln-c-navigation,
    .ln-c-navigation__item {
      float: left;
    }

    .ln-c-navigation__item + .ln-c-navigation__item {
      margin-left: $ln-space-x2;
    }

    .ln-c-navigation--pill > .ln-c-navigation__item {
      align-items: center;
      display: inline-flex;
      height: $ln-navigation-height;
    }
  }

  .ln-c-navigation__item--has-dropdown {
    position: relative;
  }

  .ln-c-navigation__link,
  .ln-c-navigation__dropdown-toggle {
    @include ln-font-size($ln-font-button);

    color: currentColor;
    display: block;
    font-family: $ln-font-family-brand;
    font-weight: $ln-font-weight-medium;
    position: relative;
    text-decoration: none;

    @include ln-respond-to('nav') {
      @include ln-font-size-fixed('display-1');

      padding: 0 $ln-space;
    }

    @include ln-respond-to('max-nav') {
      padding: $ln-navigation-collapsed-item-spacing
        $ln-navigation-collapsed-indent;
    }

    &:hover,
    &:focus {
      color: $ln-color-text-alpha-active;
      outline: none;
    }

    &::before {
      background: $ln-color-alpha;
      bottom: $ln-border-size;
      content: ' ';
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity $ln-transition-duration;

      @include ln-respond-to('nav') {
        height: $ln-nav-item-bar-width;
        right: 0;
      }

      @include ln-respond-to('max-nav') {
        bottom: 2px;
        top: 2px;
        width: $ln-nav-item-bar-width;
      }
    }

    @include ln-respond-to('nav') {
      .ln-c-navigation--pill > .ln-c-navigation__item > & {
        @include ln-font('button');
        @include ln-transition-properties-with-focus(
          ('background-color', color)
        );

        border-radius: $ln-size-def / 2;
        line-height: $ln-size-def;
        padding: 0 $ln-space-x3;

        &.is-active,
        &.is-open {
          background-color: $ln-color-alpha-background;
          color: $ln-color-text-on-alpha;
        }

        @include ln-hocus {
          background-color: $ln-color-alpha-background-active;
          color: $ln-color-text-on-alpha-active;
        }

        &::before {
          display: none;
        }
      }

      .ln-c-navigation--light.ln-c-navigation--pill > .ln-c-navigation__item & {
        color: $ln-color-text-light;

        &.is-active,
        &.is-open {
          background-color: $ln-color-light-background;
          color: $ln-color-text-on-light;
        }

        @include ln-hocus {
          background-color: $ln-color-light-background-active;
          color: $ln-color-text-on-light-active;
        }
      }

      .ln-c-navigation--dark.ln-c-navigation--pill > .ln-c-navigation__item & {
        color: $ln-color-text-dark;

        &.is-active,
        &.is-open {
          background-color: $ln-color-dark-background;
          color: $ln-color-text-on-dark;
        }

        @include ln-hocus {
          background-color: $ln-color-dark-background-active;
          color: $ln-color-text-on-dark-active;
        }
      }
    }
  }

  .ln-c-navigation__link.is-active::before,
  .ln-c-navigation__link:hover::before,
  .ln-c-navigation__link:focus::before,
  .ln-c-navigation__dropdown-toggle.is-active::before,
  .ln-c-navigation__dropdown-toggle:hover::before,
  .ln-c-navigation__dropdown-toggle:focus::before {
    opacity: 1;
  }

  .ln-c-navigation__dropdown-toggle {
    background: $ln-color-transparent;
    line-height: inherit;
    padding-right: calc(#{$ln-space * 2} + #{$dropdown-icon-size});
    position: relative;

    @include ln-respond-to('max-nav') {
      text-align: left;
      width: 100%;
    }
  }

  .ln-c-navigation--pill
    > .ln-c-navigation__item
    > .ln-c-navigation__link.ln-c-navigation__dropdown-toggle {
    padding-right: calc(#{$ln-space * 3} + #{$dropdown-icon-size});
  }

  .ln-c-navigation__dropdown-icon {
    background-image: url('data:image/svg+xml;base64, #{$ln-icon-arrow-down}');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: $dropdown-icon-size;
    margin-left: $ln-space;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $dropdown-icon-size;

    .ln-c-navigation--light & {
      background-image: url('data:image/svg+xml;base64, #{$ln-icon-arrow-down-light}');
    }
  }

  .ln-c-navigation__dropdown-toggle:hover .ln-c-navigation__dropdown-icon,
  .ln-c-navigation__dropdown-toggle:focus .ln-c-navigation__dropdown-icon {
    background-image: url('data:image/svg+xml;base64, #{$ln-icon-arrow-down-alpha-active}');
  }

  /* stylelint-disable selector-max-compound-selectors */
  .ln-c-navigation--pill
    > .ln-c-navigation__item
    > .ln-c-navigation__link.ln-c-navigation__dropdown-toggle:hover
    .ln-c-navigation__dropdown-icon,
  .ln-c-navigation--pill
    > .ln-c-navigation__item
    > .ln-c-navigation__link.ln-c-navigation__dropdown-toggle:focus
    .ln-c-navigation__dropdown-icon {
    background-image: url('data:image/svg+xml;base64, #{$ln-icon-arrow-down-on-alpha}');
  }

  .ln-c-navigation--light
    .ln-c-navigation__dropdown-toggle.is-open
    .ln-c-navigation__dropdown-icon,
  .ln-c-navigation--pill.ln-c-navigation--light
    > .ln-c-navigation__item
    > .ln-c-navigation__link.ln-c-navigation__dropdown-toggle:hover
    .ln-c-navigation__dropdown-icon,
  .ln-c-navigation--pill.ln-c-navigation--light
    > .ln-c-navigation__item
    > .ln-c-navigation__link.ln-c-navigation__dropdown-toggle:focus
    .ln-c-navigation__dropdown-icon {
    background-image: url('data:image/svg+xml;base64, #{$ln-icon-arrow-down-on-light}');
  }
  /* stylelint-enable selector-max-compound-selectors */

  .ln-c-navigation__dropdown-toggle.is-open::before {
    @include ln-respond-to('max-nav') {
      bottom: 0;
      opacity: 1;
    }
  }

  .ln-c-navigation__dropdown-toggle.is-open .ln-c-navigation__dropdown-icon {
    transform: translateY(-50%) rotate(180deg);
  }

  .ln-c-navigation__dropdown-toggle.is-open + .ln-c-navigation__dropdown {
    @include ln-visually-hidden(false, true);

    @include ln-respond-to('nav') {
      position: absolute;
      transform: scale(1);
      transition: transform $ln-transition-duration,
        opacity $ln-transition-duration;
      white-space: nowrap;
    }
  }

  .ln-c-navigation__dropdown {
    @include ln-visually-hidden;

    background: $ln-color-navigation-background;
    left: 0;
    list-style: none;
    margin: 0;
    top: 100%;
    z-index: $ln-layer-3;

    @include ln-respond-to('nav') {
      border: $ln-border-size solid $ln-color-nav-border;
      border-top: 0;
      transform: scale(1, 0);
      transform-origin: 50% 0;
    }
  }

  .ln-c-navigation__dropdown .ln-c-navigation__item {
    float: none;
    margin-left: 0;
  }

  .ln-c-navigation__dropdown .ln-c-navigation__link {
    &::before {
      bottom: 2px;
      height: auto;
      top: 2px;
      width: $ln-nav-item-bar-width;
    }

    @include ln-respond-to('nav') {
      line-height: ln-line-height-fixed($ln-font-body);
      padding: $ln-space $ln-space-x2;
    }

    @include ln-respond-to('max-nav') {
      padding-left: ($ln-navigation-collapsed-indent * 2);
      padding-right: ($ln-navigation-collapsed-indent * 2);

      &::before {
        background-color: $ln-color-black;
        bottom: 0;
        opacity: 1;
        top: 0;
      }

      &:hover::before,
      &:focus::before {
        background-color: $ln-color-alpha;
      }
    }
  }
}
