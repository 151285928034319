@if $use-ln-header {
  $offset: ln-map-deep-get($ln-scaled-space, 'def', 'sm');

  .ln-c-header-body {
    position: relative;

    @include ln-respond-to('max-nav') {
      margin-top: $ln-navigation-collapsed-height;
    }
  }
}
