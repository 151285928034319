////
/// @group utilities/typography
////

// Disabling stylelint to preserve indentation as sassdoc can't parse indented annotations
// stylelint-disable prettier/prettier
@if ($use-ln-fonts) {
  @each $key, $value in $ln-fonts-transformed {
/// Sets the font styling to match those defined for the related key in the `$ln-fonts` list
/// @name Font
/// @example markup
///   <p class="ln-u-body-1">Body content</p>
///
    .ln-u-#{$key} {
      @include ln-font($key, true);
    }

    .ln-u-#{$key}-fixed {
      @include ln-font-fixed($key, true);
    }
  }
}

@if ($use-ln-font-size) {
  @each $font-key, $values in $ln-fonts-transformed {
/// Sets the `font-size` to match the related key in the `$ln-fonts` list
/// @name Size
/// @example markup
///   <p class="ln-u-font-size-display-1">Heading Display 1</p>
///   <p class="ln-u-font-size-fixed-display-1">Heading Display 1</p>
///
    .ln-u-font-size-#{$font-key} {
      @include ln-font-size($font-key, true);
    }

    .ln-u-font-size-fixed-#{$font-key} {
      @include ln-font-size-fixed($font-key, true);
    }
  }
}

@if $use-ln-font-styling {
/// Utility classes to set the `font-weight` for an element with the value derived from the
/// `$ln-font-weight-[key]` settings
/// @name Weight
/// @example markup
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-font-weight-regular">ln-u-font-weight-regular</p>
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-font-weight-medium">ln-u-font-weight-medium</p>
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-font-weight-bold">ln-u-font-weight-bold</p>
///
  .ln-u-font-weight-regular {
    font-weight: $ln-font-weight-regular !important;
  }

  .ln-u-font-weight-medium {
    font-weight: $ln-font-weight-medium !important;
  }

  .ln-u-font-weight-bold {
    font-weight: $ln-font-weight-bold !important;
  }

/// Utility classes to control the `text-decoration` & `font-style` of an element
/// @name Styling
/// @example markup
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-text-decoration-underline">ln-u-text-decoration-underline</p>
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-text-decoration-normal">ln-u-text-decoration-normal</p>
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-text-style-italic">ln-u-text-style-italic</p>
///   <p class="ln-u-display-inline-block ln-u-soft-right ln-u-font-style-normal">ln-u-font-style-normal</p>
///

  .ln-u-text-decoration-underline {
    text-decoration: underline !important;
  }

  .ln-u-text-decoration-normal {
    text-decoration: none !important;
  }

  .ln-u-text-style-italic {
    font-style: italic !important;
  }

  .ln-u-font-style-normal {
    font-style: normal !important;
  }
}


@if $use-ln-text-alignment {
/// Sets the `text-alignment` on an element
/// @name Alignment
/// @example markup
///   <p class="ln-u-text-align-center">ln-u-text-align-center</p>
///   <p class="ln-u-text-align-right">ln-u-text-align-right</p>
///   <p class="ln-u-text-align-left">ln-u-text-align-left</p>
///
  .ln-u-text-align-center {
    text-align: center !important;
  }

  .ln-u-text-align-right {
    text-align: right !important;
  }

  .ln-u-text-align-left {
    text-align: left !important;
  }
}

@if $use-ln-font-family {
  .ln-u-font-family-base {
    font-family: $ln-font-family-base !important;
  }

  .ln-u-font-family-brand {
    font-family: $ln-font-family-brand !important;
  }
}
