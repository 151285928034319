@if $use-ln-footer {
  .ln-c-footer {
    background-color: $ln-color-footer-background;
    color: $ln-color-footer-text;

    @include ln-soft;
  }

  .ln-c-footer__nav {
    @include ln-soft;
    @include ln-push('def', false, true);

    background-color: $ln-color-footer-nav-background;
  }
}
