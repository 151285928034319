@if $use-ln-bare-link {
  /// @deprecated Use .ln-c-link.ln-c-link--bare
  .ln-o-bare-link {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: inherit;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }
  }
}
