/* stylelint-disable max-nesting-depth */
@if $use-ln-container {
  /*
  Containers can be used to constrain the maximum width of content
  and/or center it with optional padding applied.

  An example use-case is to provide a site wrap for web pages.
  */

  .ln-o-container {
    margin: 0 auto;
  }

  .ln-o-container--free {
    margin: 0;
  }

  @each $key, $size in $ln-container-sizes {
    $size-modifier: '';

    @if ($key != 'def') {
      $size-modifier: '--#{$key}';
    }

    $width-rem: ($size / $ln-browser-font-size-default) * 1rem;

    .ln-o-container#{$size-modifier} {
      max-width: $width-rem;
    }

    @each $key, $breakpoints in $ln-container-soft-variants {
      $suffix: '';

      @if ($key != 'def') {
        $suffix: '-#{$key}';
      }

      .ln-o-container#{$size-modifier}.ln-o-container--soft#{$suffix} {
        @each $key, $value in $breakpoints {
          @if ($key == 'def') {
            max-width: ($width-rem + $value * 2);
            padding-left: $value;
            padding-right: $value;
          } @else {
            @include ln-respond-to($key) {
              max-width: ($width-rem + $value * 2);
              padding-left: $value;
              padding-right: $value;
            }
          }
        }
      }
    }
  }
}

.ln-o-container.ln-o-container--fluid {
  max-width: none;
  width: 100%;
}
