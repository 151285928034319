@mixin ln-c-list-group-variant($variant, $color, $background-color) {
  .ln-c-list-group--#{$variant} {
    .ln-c-list-group__item {
      color: map-get($color, 'default');

      &.is-active {
        color: map-get($color, 'active');

        &::before {
          background-color: map-get($background-color, 'default');
        }
      }
    }

    /* stylelint-disable max-nesting-depth */
    &.ln-c-list-group--actionable .ln-c-list-group__item {
      @include ln-hocus {
        background-color: map-get($background-color, 'highlight');
        color: map-get($color, 'active');
      }

      &.is-active,
      &.is-hover {
        font-weight: normal;

        @include ln-hocus {
          color: map-get($color, 'active');

          &::before {
            background-color: map-get($background-color, 'active');
          }
        }
      }
    }
    /* stylelint-enable max-nesting-depth */

    &.ln-c-list-group--pill .ln-c-list-group__item.is-active,
    &.ln-c-list-group--pill .ln-c-list-group__item.is-hover {
      background-color: map-get($background-color, 'default');
      color: map-get($color, 'on');

      @include ln-hocus {
        background-color: map-get($background-color, 'active');
        color: map-get($color, 'onActive');
      }
    }
  }
}

@mixin ln-c-list-group-inline() {
  display: flex;
  flex-direction: row;

  .ln-c-list-group--dropdown {
    background-color: $ln-color-white;
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateY(100%);
    width: 16rem;
    z-index: $ln-layer-3;

    &.ln-c-list-group--dropdown-right {
      left: auto;
      right: 0;
    }

    .ln-c-list-group__item {
      padding-left: $ln-space-x2;
    }
  }

  .ln-c-list-group__dropdown-arrow {
    margin-left: $ln-space;
    position: static;
    transform: none;
  }

  .is-active + .ln-c-list-group--dropdown,
  .is-hover + .ln-c-list-group--dropdown {
    box-shadow: 0 4px 8px 0 rgba($ln-color-black, 0.12);
    max-height: 100vh;
    padding: $ln-space 0;
  }

  .is-active > .ln-c-list-group__dropdown-arrow,
  .is-hover > .ln-c-list-group__dropdown-arrow {
    transform: rotate(-180deg);
  }

  &.ln-c-list-group--spaced > * {
    margin-bottom: 0;
    margin-right: $ln-space;
  }

  .ln-c-list-group__item::before {
    bottom: 0;
    height: $ln-status-bar-width;
    top: auto;
    width: 100%;
  }
}

@if $use-ln-list-group {
  .ln-c-list-group {
    list-style: none;
    margin: 0;

    &.ln-c-list-group--full {
      width: 100%;
    }
  }

  .ln-c-list-group__item {
    @include ln-transition-properties-with-focus(background-color color);

    align-items: center;
    background-color: $ln-color-transparent;
    border-radius: $ln-border-radius;
    color: $ln-color-text;
    display: inline-flex;
    font-family: inherit;
    font-weight: inherit;
    justify-content: flex-start;
    min-height: $ln-size-def;
    padding: $ln-space $ln-space-x2;
    position: relative;
    text-decoration: none;
    width: 100%;

    &.is-active,
    &.is-hover {
      color: $ln-color-text-alpha;

      &::before {
        opacity: 1;
      }
    }

    .ln-c-list-group--full & {
      border-radius: 0;

      &::before {
        background-color: $ln-color-alpha-background;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition-duration: $ln-transition-duration;
        transition-property: background-color, opacity;
        width: $ln-status-bar-width;
      }

      &.is-active::before {
        opacity: 1;
      }
    }

    /* stylelint-disable max-nesting-depth */
    .ln-c-list-group--actionable & {
      @include ln-hocus {
        background-color: $ln-color-base-background-highlight;
        color: $ln-color-text-active;
      }

      &.is-active,
      &.is-hover {
        font-weight: bold;

        @include ln-hocus {
          background-color: $ln-color-alpha-background-highlight;
          color: $ln-color-text-alpha-active;

          &::before {
            background-color: $ln-color-alpha-background-active;
          }
        }
      }
    }
    /* stylelint-enable max-nesting-depth */

    .ln-c-list-group--hard > * > & {
      padding: 0;
    }

    .ln-c-list-group--sm & {
      line-height: $ln-size-sm;
      min-height: $ln-size-sm;
    }

    .ln-c-list-group--lg & {
      line-height: $ln-size-lg;
      min-height: $ln-size-lg;
    }
  }

  .ln-c-list-group__dropdown-container {
    position: relative;

    > .ln-c-list-group__item {
      display: inline-block;
      text-align: left;
    }

    .ln-c-list-group__item::before {
      display: none;
    }
  }

  .ln-c-list-group__dropdown-arrow {
    position: absolute;
    right: $ln-space-x2;
    top: 50%;
    transform: translateY(-50%);

    .is-active > & {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  .ln-c-list-group--dropdown {
    line-height: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height $ln-transition-duration;

    &.ln-c-list-group--dropdown-body {
      @include ln-font($ln-font-body);
    }

    &.ln-c-list-group--dropdown-button {
      @include ln-font($ln-font-button);
    }

    .ln-c-list-group__item {
      padding-left: $ln-space-x4;
    }

    .is-active + & {
      max-height: 100vh;
    }
  }

  .ln-c-list-group--pill {
    align-items: center;
    display: flex;
    flex-direction: column;

    .ln-c-list-group__item {
      align-items: center;
      border-radius: $ln-size-def / 2;
      display: inline-flex;
      height: $ln-size-def;
      line-height: $ln-size-def;
      padding: $ln-space $ln-space-x3;

      &.is-active,
      &.is-hover {
        background-color: $ln-color-alpha-background;
        color: $ln-color-text-on-alpha;

        @include ln-hocus {
          background-color: $ln-color-alpha-background-active;
          color: $ln-color-text-on-alpha-active;
        }
      }
    }

    &.ln-c-list-group--sm .ln-c-list-group__item {
      height: $ln-size-sm;
      padding: $ln-space-half $ln-space-x2;
    }

    &.ln-c-list-group--lg .ln-c-list-group__item {
      height: $ln-size-lg;
      padding: $ln-space-x2 $ln-space-x4;
    }
  }

  .ln-c-list-group--spaced > * {
    margin-bottom: $ln-space;
  }

  .ln-c-list-group--inline {
    @include ln-c-list-group-inline;
  }

  .ln-c-list-group--inline\@nav {
    @include ln-respond-to('nav') {
      @include ln-c-list-group-inline;
    }
  }

  @include ln-c-list-group-variant(
    'light',
    (
      default: $ln-color-text-light,
      active: $ln-color-text-light-active,
      on: $ln-color-text-on-light,
      onActive: $ln-color-text-on-light-active,
    ),
    (
      default: $ln-color-light-background,
      active: $ln-color-light-background-active,
      highlight: $ln-color-light-background-highlight,
    )
  );

  @include ln-c-list-group-variant(
    'dark',
    (
      default: $ln-color-text-dark,
      active: $ln-color-text-dark-active,
    ),
    (
      default: $ln-color-dark-background,
      active: $ln-color-dark-background-active,
      highlight: $ln-color-dark-background-highlight,
    )
  );

  // IE10 + fix for text alignment https://github.com/philipwalton/flexbugs#flexbug-3
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ln-c-list-group__item {
      line-height: $ln-size-def - ($ln-space-px * 2);
    }
  }
}
