@if $use-ln-header {
  .ln-c-header {
    color: $ln-color-header-text;
  }

  @include ln-respond-to('max-nav') {
    .ln-c-header {
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;

      &::before {
        backface-visibility: hidden;
        background: $ln-color-white;
        bottom: 0;
        content: ' ';
        left: 0;
        max-width: 100%;
        position: absolute;
        top: $ln-navigation-collapsed-height; // 1px to account for border
        transform: translate(-100%, 0);
        width: $ln-navigation-collapsed-width;
        z-index: $ln-layer-2;
      }
    }

    .ln-c-header.is-open {
      height: 100vh;
      overflow: auto;
      pointer-events: auto;
      z-index: $ln-layer-4;

      &::before {
        transform: translate(0, 0);
        transition: transform $ln-transition-duration;
      }
    }
  }

  .ln-c-header__toggle {
    background: $ln-color-transparent;
    float: left;
    height: $ln-navigation-collapsed-height;
    line-height: inherit;
    pointer-events: auto;
    position: relative;
    width: $ln-navigation-collapsed-height;
    z-index: $ln-layer-4; // to position above title

    &:focus {
      z-index: $ln-layer-4; // override focus z-index
    }
  }

  .ln-c-header__toggle-overlay {
    background: $ln-overlay-background;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: scale(0);
    width: 100%;
    z-index: $ln-layer-1;

    .ln-c-header.is-open & {
      opacity: 1;
      transform: scale(1);
      transition: opacity $ln-transition-duration;
    }
  }

  .ln-c-header__toggle-overlay,
  .ln-c-header__toggle {
    @include ln-respond-to('nav') {
      display: none;
    }
  }

  .ln-c-header__inner {
    position: relative;
    z-index: $ln-layer-2;
  }
}
