/*
Media query breakpoints.
You can choose how many, the namespace and breakpoint value.
Example width class with default values: Note the `@`symbol prepends
the namespace.

`u-1/2@sm-up u-2/5@lg-up`

/*
1. Takes 3 arguments:
   - The property (Width OR push OR pull),
   - The fractions list that corresponds to the property,
   - The breakpoint (can be an item in the `$ln-breakpoints` list or null).
2. Loop through all of the fractions
3. If the property within the current fraction has been set feature-toggled
4. If a breakpoint was passed into the mixin...
5. Generate a class name based on:
   - The namespace in the current fraction +
   - An escaped @ symbol as a responsive suffix +
   - The namespace from the current breakpoint item.
6. Generate the property and the value, which is the fraction e.g. 1/3
   converted into a percentage.
7. A breakpoint was not passed into the mixin...
8. Generate the same as above but without any responsive suffixes.
 */
@mixin ln-set-fractions($ln-property, $ln-fractions, $ln-breakpoint) {
  // [1]
  @each $ln-fraction in $ln-fractions {
    // [2]
    @if nth($ln-fraction, 3) {
      // [3]
      /* stylelint-disable max-nesting-depth */
      @if $ln-breakpoint {
        // [4]
        .#{nth($ln-fraction, 1)}\@#{nth($ln-breakpoint, 1)} {
          // [5]
          #{$ln-property}: nth($ln-fraction, 2) * 100%; // [6]
        }
      } @else {
        // [7]
        .#{nth($ln-fraction, 1)} {
          // [8]
          #{$ln-property}: nth($ln-fraction, 2) * 100%;
        }
      }
      // stylelint-enable
    }
  }
}

/*
1. Takes 2 arguments:
   - The fractions list that corresponds to the property (Width/Push/pull),
   - An optional namespace that will form a segment of the eventual class name.
2. Call the `set-fractions mixin` passing in:
   - The property,
   - The fractions list,
3. Check if any breakpoints are defined.
4. Loop through the breakpoints.
5. Generate a mobile first media query taking with width value from the
   current breakpoint's list (2nd value) and add `1` to the value.
2. Call the `set-fractions mixin` passing in:
   - The property,
   - The fractions list,
   - the current breakpoint
 */
@mixin ln-make-offsets($ln-property, $ln-fractions) {
  // [1]
  @include ln-set-fractions($ln-property, $ln-fractions, null); // [2]

  @if $ln-breakpoints {
    // [3]
    @each $ln-breakpoint in $ln-breakpoints {
      // [4]
      @media (min-width: nth($ln-breakpoint, 2)) {
        // [5]
        @include ln-set-fractions($ln-property, $ln-fractions, $ln-breakpoint);
      }
    }
  }
}
