/*
Inspired by Inuit's Reset, plus a few additions of my own:
A more considered reset; more of a restart...
As per: csswizardry.com/2011/10/reset-restarted
*/

// Setup border-box.
* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  // Better font rendering.
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// The usual.
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
figure,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}

// Give a help cursor to elements that give extra info on `:hover`.
/* stylelint-disable selector-no-qualifying-type */
abbr[title],
dfn[title] {
  cursor: help;
}
// stylelint-enable

// Remove underlines from potentially troublesome elements.
u,
ins {
  text-decoration: none;
}

// Apply faux underline via `border-bottom`.
ins {
  border-bottom: $ln-border-size solid;
}

/*
1. make image fluid & maintain aspect ratio.
2. So that `alt` text is visually offset if images don’t load.
*/
img {
  font-style: italic; // [2]
  height: auto; // [1]
  max-width: 100%; // [1]
}

// Remove borders from images wrapped in anchors.
a img {
  border: none;
}

// Remove border from fieldset
fieldset {
  border: 0;
}

// Remove padding and border from buttons & provide cursor interaction
button {
  border: 0;
  cursor: pointer;
  padding: 0;
}

// Disable resizing of textareas
textarea {
  resize: none;
}

/*
  Force inputs to follow the base-line height, this
  allows our .btn classes to have equal height when both
  have equal padding values applied.
*/
label,
input,
textarea,
button,
select,
option {
  line-height: ln-line-height-fixed($ln-font-body);
}
