@if $use-ln-form-option {
  $icon-size: 24px;
  $radio-dot-size: 14px;
  $switch-bg-width: $ln-space-px * 5;
  $switch-dot-size: 20px;
  $border-width-small: $ln-border-size;
  $border-width-large: $ln-border-size + 1;

  .ln-c-form-option {
    align-items: center;
    display: flex;
    min-height: $ln-size-def;
  }

  .ln-c-form-option--inline {
    display: inline-flex;
  }

  .ln-c-form-option__input {
    @include ln-visually-hidden(false);
  }

  .ln-c-form-option__label {
    @include ln-transition-properties-with-focus(background-color);

    border-radius: $ln-border-radius;
    color: $ln-color-text;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: $ln-size-def;
    padding: $ln-space $ln-space-x2;
    padding-left: calc(#{$icon-size} + #{$ln-space-x4});
    position: relative;
    vertical-align: middle;

    &::before {
      background-color: $ln-color-white;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      border: $border-width-small solid $ln-color-base-border;
      content: ' ';
      height: $icon-size;
      left: $ln-space-x2;
      position: absolute;
      top: calc(50% - #{$icon-size / 2});
      transition-duration: $ln-transition-duration;
      transition-property: background-color, border-color;
      width: $icon-size;
    }

    .ln-c-form-option--outlined & {
      border: $ln-border-size solid $ln-color-base-border;
    }

    .ln-c-form-option--full & {
      width: 100%;
    }
  }

  .ln-c-form-option--hide-label {
    .ln-c-form-option__label {
      padding-left: calc(#{$icon-size} + #{$ln-space-x2});

      > span {
        @include ln-visually-hidden;
      }
    }
  }

  .ln-c-form-option__input {
    &:not(:disabled) {
      ~ .ln-c-form-option__label:hover,
      &:focus ~ .ln-c-form-option__label {
        background-color: $ln-color-base-background-highlight;
        color: $ln-color-text-active;

        /* stylelint-disable-next-line max-nesting-depth */
        .ln-c-form-group.has-error & {
          background-color: $ln-color-error-background-highlight;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .ln-c-form-group.has-warning & {
          background-color: $ln-color-warning-background-highlight;
        }
      }

      .ln-c-form-option--outlined & ~ .ln-c-form-option__label:hover,
      .ln-c-form-option--outlined &:focus ~ .ln-c-form-option__label {
        border-color: $ln-color-base-border-active;
        box-shadow: ln-text-input-inset-box-shadow($ln-color-base-border-active),
          $ln-focus-ring-transparent;
      }

      .ln-c-form-option--outlined &:focus ~ .ln-c-form-option__label {
        box-shadow: ln-text-input-inset-box-shadow($ln-color-base-border-active),
          $ln-focus-ring;
      }

      &:checked ~ .ln-c-form-option__label::before {
        border-width: $border-width-large;
      }

      &:checked ~ .ln-c-form-option__label:hover,
      &:checked:focus ~ .ln-c-form-option__label {
        background-color: $ln-color-alpha-background-highlight;

        /* stylelint-disable-next-line max-nesting-depth */
        &::before {
          border-color: $ln-color-alpha-border-active;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        &::after {
          background-color: $ln-color-alpha-border-active;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .ln-c-form-group.has-error & {
          background-color: $ln-color-error-background-highlight;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        .ln-c-form-group.has-warning & {
          background-color: $ln-color-warning-background-highlight;
        }
      }

      .ln-c-form-option--outlined &:checked ~ .ln-c-form-option__label {
        border-color: $ln-color-alpha-border;
      }

      .ln-c-form-option--outlined &:checked ~ .ln-c-form-option__label:hover,
      .ln-c-form-option--outlined &:checked:focus ~ .ln-c-form-option__label {
        border-color: $ln-color-alpha-border-active;
        box-shadow: ln-text-input-inset-box-shadow(
            $ln-color-alpha-border-active
          ),
          $ln-focus-ring-transparent;
      }

      .ln-c-form-option--outlined &:checked:focus ~ .ln-c-form-option__label {
        box-shadow: ln-text-input-inset-box-shadow(
            $ln-color-alpha-border-active
          ),
          $ln-focus-ring;
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .ln-c-form-group.has-error
        .ln-c-form-option--outlined
        &
        ~ .ln-c-form-option__label {
        border-color: $ln-color-error;

        /* stylelint-disable-next-line selector-max-compound-selectors */
        &:hover {
          box-shadow: ln-text-input-inset-box-shadow($ln-color-error),
            $ln-focus-ring-transparent;
        }
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .ln-c-form-group.has-warning
        .ln-c-form-option--outlined
        &
        ~ .ln-c-form-option__label {
        border-color: $ln-color-warning;

        /* stylelint-disable-next-line selector-max-compound-selectors */
        &:hover {
          box-shadow: ln-text-input-inset-box-shadow($ln-color-warning),
            $ln-focus-ring-transparent;
        }
      }

      .ln-c-form-option--outlined &:focus ~ .ln-c-form-option__label,
      .ln-c-form-option--outlined &:checked:focus ~ .ln-c-form-option__label {
        /* stylelint-disable-next-line max-nesting-depth,selector-max-compound-selectors */
        .ln-c-form-group.has-error & {
          box-shadow: ln-text-input-inset-box-shadow($ln-color-error),
            $ln-focus-ring;
        }

        /* stylelint-disable-next-line max-nesting-depth,selector-max-compound-selectors */
        .ln-c-form-group.has-warning & {
          box-shadow: ln-text-input-inset-box-shadow($ln-color-warning),
            $ln-focus-ring;
        }
      }
    }

    &:not(:disabled) ~ .ln-c-form-option__label:hover,
    &:focus ~ .ln-c-form-option__label {
      color: $ln-color-text-active;

      &::before {
        border-color: $ln-color-base-border-active;
        border-width: $border-width-large;

        .ln-c-form-group.has-error & {
          border-color: $ln-color-error;
        }

        .ln-c-form-group.has-warning & {
          border-color: $ln-color-warning;
        }
      }
    }
  }

  .ln-c-form-option__label::before {
    .ln-c-form-group.has-error & {
      border-color: $ln-color-error;
    }

    .ln-c-form-group.has-warning & {
      border-color: $ln-color-warning;
    }
  }

  // Radio
  .ln-c-form-option--radio {
    .ln-c-form-option__label {
      &::before {
        border-radius: 50%;
      }

      &::after {
        border-radius: 50%;
        content: ' ';
        height: $radio-dot-size;
        left: calc(#{$ln-space-x2} + #{($icon-size - $radio-dot-size) / 2});
        position: absolute;
        top: calc(50% - #{$radio-dot-size / 2});
        transition: background-color $ln-transition-duration;
        width: $radio-dot-size;
      }
    }

    .ln-c-form-option__input:checked ~ .ln-c-form-option__label {
      &::before {
        border-color: $ln-color-alpha-border;

        // stylelint-disable-next-line
        .ln-c-form-group.has-error & {
          border-color: $ln-color-error;
        }

        // stylelint-disable-next-line
        .ln-c-form-group.has-warning & {
          border-color: $ln-color-warning;
        }
      }

      &::after {
        background-color: $ln-color-alpha-border;

        // stylelint-disable-next-line
        .ln-c-form-group.has-error & {
          background-color: $ln-color-error;
        }

        // stylelint-disable-next-line
        .ln-c-form-group.has-warning & {
          background-color: $ln-color-warning;
        }
      }
    }
  }

  // Checkbox
  .ln-c-form-option--checkbox {
    .ln-c-form-option__input:checked ~ .ln-c-form-option__label {
      &::before {
        background-color: $ln-color-alpha-border;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8dGl0bGU+CiAgICBMaW5lCiAgPC90aXRsZT4KICA8cGF0aCBkPSJNMSA2LjcxNGw0IDRMMTIuNzE0IDEiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4=');
        background-size: 70%;
        border-color: $ln-color-alpha-border;
        border-radius: 1px;

        // stylelint-disable-next-line
        .ln-c-form-group.has-error & {
          background-color: $ln-color-error;
          border-color: $ln-color-error;
        }

        // stylelint-disable-next-line
        .ln-c-form-group.has-warning & {
          background-color: $ln-color-warning;
          border-color: $ln-color-warning;
        }
      }
    }

    .ln-c-form-option__input:checked ~ .ln-c-form-option__label:hover,
    .ln-c-form-option__input:checked:focus ~ .ln-c-form-option__label {
      /* stylelint-disable-next-line max-nesting-depth */
      &::before {
        background-color: $ln-color-alpha-background-active;
      }
    }
  }

  // Switch
  .ln-c-form-option--switch {
    .ln-c-form-option__label {
      padding-left: calc(#{$switch-bg-width} + #{$ln-space-x4});

      &::before {
        border-radius: $icon-size / 2;
        width: $switch-bg-width;
      }

      &::after {
        background-color: $ln-color-white;
        border: $border-width-small solid $ln-color-base-border;
        border-radius: 50%;
        content: '';
        height: $icon-size;
        left: $ln-space-x2;
        position: absolute;
        top: calc(50% - #{$icon-size / 2});
        transition: transform $ln-transition-duration;
        width: $icon-size;
      }
    }

    .ln-c-form-option__input:not(:disabled) ~ .ln-c-form-option__label:hover,
    .ln-c-form-option__input:focus ~ .ln-c-form-option__label {
      &::after {
        border-color: $ln-color-base-border-active;
        border-width: $border-width-large;
      }
    }

    .ln-c-form-option__input:checked ~ .ln-c-form-option__label {
      &::before {
        background-color: $ln-color-alpha-background;
        border-color: $ln-color-alpha-border;
      }

      &::after {
        border-color: $ln-color-alpha-border;
        border-width: $border-width-large;
        transform: translateX($switch-bg-width - $icon-size);
      }
    }

    .ln-c-form-option__input:checked:not(:disabled)
      ~ .ln-c-form-option__label:hover,
    .ln-c-form-option__input:checked:focus ~ .ln-c-form-option__label {
      &::before {
        background-color: $ln-color-alpha-background-active;
        border-color: $ln-color-alpha-border-active;
      }

      &::after {
        background-color: $ln-color-white;
        border-color: $ln-color-alpha-border-active;
      }
    }

    .ln-c-form-group.has-error & {
      .ln-c-form-option__label::after {
        border-color: $ln-color-error;
      }

      // stylelint-disable-next-line selector-max-compound-selectors
      .ln-c-form-option__input:not(:disabled)
        ~ .ln-c-form-option__label:hover::after,
      .ln-c-form-option__input:focus ~ .ln-c-form-option__label::after {
        border-color: $ln-color-error;
      }

      // stylelint-disable-next-line selector-max-compound-selectors
      .ln-c-form-option__input:checked ~ .ln-c-form-option__label::before {
        background-color: $ln-color-error;
        border-color: $ln-color-error;
      }
    }

    .ln-c-form-group.has-warning & {
      .ln-c-form-option__label::after {
        border-color: $ln-color-warning;
      }

      // stylelint-disable-next-line selector-max-compound-selectors
      .ln-c-form-option__input:not(:disabled)
        ~ .ln-c-form-option__label:hover::after,
      .ln-c-form-option__input:focus ~ .ln-c-form-option__label::after {
        border-color: $ln-color-warning;
      }

      // stylelint-disable-next-line selector-max-compound-selectors
      .ln-c-form-option__input:checked ~ .ln-c-form-option__label::before {
        background-color: $ln-color-warning;
        border-color: $ln-color-warning;
      }
    }
  }

  // Common
  .ln-c-form-option__input:checked:disabled {
    .ln-c-form-option--checkbox & ~ .ln-c-form-option__label::before,
    .ln-c-form-option--switch & ~ .ln-c-form-option__label::before,
    .ln-c-form-option--radio & ~ .ln-c-form-option__label::after {
      background-color: $ln-color-disabled;
      border-color: $ln-color-disabled;
    }

    .ln-c-form-option--switch & ~ .ln-c-form-option__label::after,
    .ln-c-form-option--radio & ~ .ln-c-form-option__label::before {
      border-color: $ln-color-disabled;
    }
  }

  .ln-c-form-option__input:disabled ~ .ln-c-form-option__label {
    @include ln-disabled-style;
  }

  .ln-c-form-option__input:focus ~ .ln-c-form-option__label {
    box-shadow: $ln-focus-ring;

    &:not(:focus-visible) {
      box-shadow: none;
    }
  }

  .js-focus-visible {
    .ln-c-form-option__input:focus ~ .ln-c-form-option__label {
      box-shadow: none;
    }

    .ln-c-form-option__input.focus-visible ~ .ln-c-form-option__label {
      box-shadow: $ln-focus-ring;
    }
  }

  // IE10 + fix for text alignment https://github.com/philipwalton/flexbugs#flexbug-3
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ln-c-form-option__label {
      line-height: $ln-size-def - ($ln-space-px * 2);
    }
  }
}
