/// Apply styling on hover or focus (hocus)
/// @group mixins
/// @content
@mixin ln-hocus {
  &:hover,
  &:focus {
    @content;
  }
}

/// Apply styling on hover or focus if not disabled
/// @group mixins
/// @content
@mixin ln-hocus-not-disabled {
  &:not(:disabled):not(.is-disabled) {
    @include ln-hocus {
      @content;
    }
  }
}

/// Apply styling on hover or item within has received focus
/// IE doesn't currently support :focus-within
/// @group mixins
/// @content
@mixin ln-hover-focus-within {
  &:hover {
    @content;
  }
  // Note: IE 11 (and possibly Edge) refuses to process entire rule when encountering :focus-within (hence separate @content blocks)
  // https://stackoverflow.com/questions/41246634/why-do-css-comma-separated-selectors-break-entire-rule-when-one-part-is-unknown
  &:focus-within {
    @content;
  }
}

/// Apply styling on direct focus or item within has received focus
/// IE doesn't currently support :focus-within
/// @group mixins
/// @content
@mixin ln-focus-focus-within {
  &:focus {
    @content;
  }
  // Note: IE 11 (and possibly Edge) refuses to process entire rule when encountering :focus-within (hence separate @content blocks)
  // https://stackoverflow.com/questions/41246634/why-do-css-comma-separated-selectors-break-entire-rule-when-one-part-is-unknown
  &:focus-within {
    @content;
  }
}

/// Apply styling on hover, focus or item within has received focus
/// IE doesn't currently support :focus-within
/// @group mixins
/// @content
@mixin ln-hover-focus-focus-within {
  &:hover,
  &:focus {
    @content;
  }
  // Note: IE 11 (and possibly Edge) refuses to process entire rule when encountering :focus-within (hence separate @content blocks)
  // https://stackoverflow.com/questions/41246634/why-do-css-comma-separated-selectors-break-entire-rule-when-one-part-is-unknown
  &:focus-within {
    @content;
  }
}
