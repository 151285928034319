@if $use-ln-justified-list {
  // Removes list styling, throws the times into a justified horizontal alignment.

  .ln-o-justified-list {
    display: table;
    margin: 0;
    table-layout: fixed;
    width: 100%;
  }

  .ln-o-justified-list__item {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  // Close @if
}
