@if $use-ln-button-group {
  .ln-c-button-group {
    @include ln-soft-top;
    @include ln-push-bottom;

    &.ln-c-button-group--action-bar {
      display: flex;
      flex-direction: column;

      @include ln-respond-to('xs') {
        flex-direction: row-reverse;
      }
    }
  }

  .ln-c-button-group__primary {
    float: right;

    .ln-c-button-group--action-bar & {
      float: none;
      margin-bottom: $ln-space-x2;

      @include ln-respond-to('xs') {
        margin-bottom: 0;
      }
    }
  }

  .ln-c-button-group__secondary {
    float: left;

    .ln-c-button-group--action-bar & {
      float: none;

      @include ln-respond-to('xs') {
        margin-right: auto;
      }
    }
  }

  .ln-c-button-group--action-bar {
    .ln-c-button-group__primary,
    .ln-c-button-group__secondary {
      display: flex;
      flex-direction: column;

      > *:not(:last-child) {
        margin-bottom: $ln-space-x2;
      }

      @include ln-respond-to('xs') {
        flex-direction: row-reverse;

        > *:not(:last-child) {
          margin-bottom: 0;
        }

        > *:not(:first-child) {
          margin-right: $ln-space-x2;
        }
      }
    }
  }
}
