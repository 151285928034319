$ln-assets-url: "~luna-style";
@import "luna-react/scss/style";
@import "../../css/user-defined";

.card-spacing {
  margin-top: 30px;
  margin-bottom: 30px;
}

.button-spacing {
  padding: 10px;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-bottom {
  margin-bottom: 30px;
}

.padding {
  padding: 30px;
  margin-bottom: 40px;
}

.align-right {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.button-container {
  padding-top: 3.35rem !important;
}

.button-container > *:last-child {
  float: right;
}

.ant-select-selection {
  height: 48px;
  border: 1px solid #8e8e8e;
  margin-right: 15px;
}

.ant-select-open .ant-select-selection {
  min-height: 48px;
}

.ant-select-selection:hover {
  border-color: #4c4c4c;
}

.ant-select {
  font-size: 100%;
  min-height: 48px;
}

.ant-select-selection-selected-value {
  margin-top: 0.6rem;
  margin-left: 0.7rem;
}

.ant-select-selection__clear {
  font-size: 20px;
  color: #4c4c4c;
  margin-right: 12px;
}

:root .ant-select-arrow {
  font-size: 25px;
  color: #4c4c4c;
  top: 35%;
  right: -5;
}

.ant-select-selection__placeholder {
  margin-top: 0.03rem;
  margin-left: 0.7rem;
}

::selection {
  border-color: #4c4c4c;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: none !important;
}

.ant-select-search__field {
  min-height: 48px;
  font-size: 1rem;
}

.ant-select-tree-title {
  font-size: 1rem;
}

.ant-select-search__field__placeholder {
  font-size: 1rem;
}

.ant-select-selection__clear:hover {
  color: #4c4c4c;
}

.display-in-line {
  display: inline-flex;
  vertical-align: sub;
  text-decoration: none;
}

.ln-c-breadcrumbs__link:hover,
.ln-c-breadcrumbs__link:focus {
  border-bottom: none;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
}

.ant-select-open .ant-select-selection {
  border-color: #f06c00;
}

.tree-select {
  width: 400px;
}

.display-inline {
  position: relative;
  display: inline;
  top: $ln-space;
}

.ln-c-button{
  min-width: fit-content!important;
}