@if $use-ln-tabs {
  .ln-c-tabs {
    white-space: nowrap;

    &.ln-c-tabs--pill {
      @include ln-push-bottom;
    }
  }

  .ln-c-tabs__list-wrap {
    height: $ln-size-def;
    overflow: hidden;
    position: relative;

    @include ln-respond-to('max-#{$ln-tabs-breakpoint}') {
      &::before,
      &::after {
        background-color: $ln-color-white;
        bottom: 0;
        content: ' ';
        display: none;
        position: absolute;
        top: 0;
        width: $ln-size-def;
        z-index: $ln-layer-1;
      }

      .has-prev-arrow & {
        padding-left: $ln-size-def;

        &::before {
          display: block;
          left: 0;
        }
      }

      .has-next-arrow & {
        padding-right: $ln-size-def;

        &::after {
          display: block;
          right: 0;
        }
      }
    }

    @include ln-respond-to($ln-tabs-breakpoint) {
      overflow: visible;

      &::before {
        background-color: $ln-color-base-border;
        bottom: 0;
        content: ' ';
        height: $ln-border-size;
        left: 0;
        position: absolute;
        right: 0;
        z-index: $ln-layer-1;
      }
    }

    .ln-c-tabs--pill & {
      &::before,
      &::after {
        display: none;
      }
    }

    @include ln-respond-to($ln-size-lg-breakpoint) {
      .ln-c-tabs--deep & {
        height: $ln-size-lg;
      }
    }
  }

  .ln-c-tabs__list {
    font-size: 0;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    transition: transform $ln-transition-duration;

    @include ln-respond-to($ln-tabs-breakpoint) {
      max-width: 100%;
      overflow-x: auto;
      transform: none !important;
      transition: none;
    }
  }

  .ln-c-tabs:not(.ln-c-tabs--pill) .ln-c-tabs__link:not(:last-child),
  .ln-c-tabs:not(.ln-c-tabs--pill):not(.ln-c-tabs--fill)
    .ln-c-tabs__link.is-active {
    border-right-width: $ln-border-size;
  }

  .ln-c-tabs__link {
    @include ln-font($ln-font-button);
    @include ln-soft-sides;
    @include ln-transition-properties-with-focus((background-color, color));

    align-items: center;
    background-color: $ln-color-white;
    border: 0 solid $ln-color-base-border;
    border-radius: 0;
    color: $ln-color-text;
    display: inline-flex;
    justify-content: center;
    min-height: $ln-size-def;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: top;

    .ln-c-tabs--deep & {
      @include ln-respond-to($ln-size-lg-breakpoint) {
        height: $ln-size-lg;
      }
    }

    @include ln-hocus {
      background-color: $ln-color-beta-background-highlight;
      color: $ln-color-text-active;
    }

    &::before {
      background-color: $ln-color-alpha-background;
      content: ' ';
      height: $ln-status-bar-width;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity $ln-transition-duration;
    }

    &:not(.ln-c-tabs__link--arrow):focus {
      z-index: $ln-layer-1;
    }

    &.is-active {
      color: $ln-color-text-alpha;
      z-index: $ln-layer-2;

      &::before {
        opacity: 1;
      }
    }

    &.is-active {
      @include ln-hocus {
        background-color: $ln-color-white;
        color: $ln-color-text-alpha-active;

        &::before {
          background-color: $ln-color-alpha-background-active;
        }
      }
    }

    .ln-c-tabs--pill & {
      background-color: $ln-color-transparent;
      border-radius: $ln-size-def / 2;
      margin-right: $ln-space;

      @include ln-hocus {
        background-color: $ln-color-base-background-highlight;
      }

      &::before {
        display: none;
      }

      &.is-active {
        background-color: $ln-color-alpha-background;
        color: $ln-color-text-on-alpha;

        @include ln-hocus {
          background-color: $ln-color-alpha-background-active;
          color: $ln-color-text-on-alpha-active;
        }
      }
    }

    .ln-c-tabs--pill.ln-c-tabs--light & {
      color: $ln-color-text-light;

      @include ln-hocus {
        background-color: $ln-color-light-background-highlight;
      }

      &.is-active {
        background-color: $ln-color-light-background;
        color: $ln-color-text-on-light;

        @include ln-hocus {
          background-color: $ln-color-light-background-active;
          color: $ln-color-text-on-light-active;
        }
      }
    }

    .ln-c-tabs--pill.ln-c-tabs--dark & {
      color: $ln-color-text-dark;

      @include ln-hocus {
        background-color: $ln-color-dark-background-highlight;
      }

      &.is-active {
        background-color: $ln-color-dark-background;
        color: $ln-color-text-on-dark;

        @include ln-hocus {
          background-color: $ln-color-dark-background-active;
          color: $ln-color-text-on-dark-active;
        }
      }
    }

    @include ln-respond-to('max-#{$ln-tabs-breakpoint}') {
      border-bottom-width: $ln-border-size;

      &.is-active {
        border-bottom-color: $ln-color-white;
      }

      &:not(.ln-c-tabs__link--arrow) {
        width: 50%;
      }

      .ln-c-tabs--pill & {
        border-bottom-width: 0;
        margin-right: 0;
      }
    }
  }

  .ln-c-tabs__link--arrow {
    border-style: none solid solid;
    display: none;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: $ln-size-def;
    z-index: $ln-layer-2;

    &::before {
      display: none;
    }
  }

  .ln-c-tabs__link--arrow-prev {
    left: 0;
  }

  .ln-c-tabs__link--arrow-next {
    right: 0;
  }

  @include ln-respond-to('max-#{$ln-tabs-breakpoint}') {
    .has-prev-arrow .ln-c-tabs__link--arrow-prev,
    .has-next-arrow .ln-c-tabs__link--arrow-next {
      display: inline-block;
    }
  }

  .ln-c-tabs--fill .ln-c-tabs__list {
    display: flex;
    width: 100%;
  }

  .ln-c-tabs--fill .ln-c-tabs__link {
    flex: 1 0 auto;
    line-height: $ln-size-def;
    overflow: hidden;
    padding-left: $ln-space;
    padding-right: $ln-space;
    text-overflow: ellipsis;

    @include ln-respond-to($ln-size-lg-breakpoint) {
      .ln-c-tabs--deep & {
        line-height: $ln-size-lg;
      }
    }
  }

  // IE10 + fix for text alignment https://github.com/philipwalton/flexbugs#flexbug-3
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ln-c-tabs__link {
      height: $ln-size-def;
    }
  }
}
