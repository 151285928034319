@mixin ln-button-disabled-style() {
  color: $ln-color-disabled;
  cursor: default;
}

@mixin ln-button-filled-disabled-style() {
  @include ln-button-disabled-style;

  background-color: $ln-color-disabled;
  color: $ln-color-white;
}

@mixin ln-button-outlined-disabled-style() {
  @include ln-button-disabled-style;

  border-color: $ln-color-disabled;
}

@if $use-ln-button {
  // stylelint-disable
  /// Buttons with different cosmetic styling can be composed with larger
  /// sizing and both types with the full-width and/or disabled.
  /// @name Button
  /// @group components
  /// @example markup - Filled
  ///   <button class="ln-c-button ln-c-button--filled">Filled</button>
  /// @example markup - Outlined
  ///   <button class="ln-c-button ln-c-button--outlined">Outlined</button>
  /// @example markup - Text button
  ///   <button class="ln-c-button ln-c-button--text">Text button</button>
  /// @example markup - Deep
  ///   <button class="ln-c-button ln-c-button--filled ln-c-button--deep">Deep</button>
  /// @example markup - Full width
  ///   <button class="ln-c-button ln-c-button--filled ln-c-button--full">Full width</button>
  /// @example markup - Disabled
  ///   <button class="ln-c-button ln-c-button--filled" disabled>Disabled</button>
  ///
  // stylelint-enable
  .ln-c-button {
    @include ln-font($ln-font-button);
    @include ln-transition-properties-with-focus(
      (background-color, border-color, color)
    );

    background-color: $ln-color-transparent;
    background-image: none;
    border-radius: $ln-border-radius;
    color: $ln-color-text;
    min-height: $ln-size-def;
    min-width: $ln-size-def;
    padding: $ln-space $ln-space-x2;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;

    // Only apply flex to non-buttons
    // https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
    &:not(button) {
      align-items: center;
      display: inline-flex;
      justify-content: center;
    }

    @include ln-hocus-not-disabled {
      background-color: $ln-color-base-background-highlight;
      color: $ln-color-text-active;
    }
  }

  /* stylelint-disable selector-list-comma-newline-after */
  .ln-c-button--filled,
  /// @deprecated - Use `.ln-c-button--primary`
  .ln-c-button--primary {
    background-color: $ln-color-alpha-background;
    color: $ln-color-text-on-alpha;

    @include ln-hocus-not-disabled {
      background-color: $ln-color-alpha-background-active;
      color: $ln-color-text-on-alpha-active;
    }

    &:not(.ln-c-button--light):not(.ln-c-button--dark) {
      @include ln-when-disabled {
        @include ln-button-filled-disabled-style;
      }
    }

    &.ln-c-button--beta {
      background-color: $ln-color-beta-background;
      color: $ln-color-text-on-beta;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-beta-background-active;
        color: $ln-color-text-on-beta-active;
      }
    }

    &.ln-c-button--light {
      background-color: $ln-color-light-background;
      color: $ln-color-text-on-light;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-light-background-active;
        color: $ln-color-text-on-light-active;
      }
    }

    &.ln-c-button--dark {
      background-color: $ln-color-dark-background;
      color: $ln-color-text-on-dark;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-dark-background-active;
        color: $ln-color-text-on-dark-active;
      }
    }
  }
  // stylelint-enable

  .ln-c-button--outlined,
  .ln-c-button--text {
    color: $ln-color-text-alpha;

    @include ln-hocus-not-disabled {
      background-color: $ln-color-alpha-background-highlight;
      color: $ln-color-text-alpha-active;
    }

    &.ln-c-button--beta {
      color: $ln-color-text-beta;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-beta-background-highlight;
        color: $ln-color-text-beta-active;
      }
    }

    &.ln-c-button--light {
      color: $ln-color-text-light;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-light-background-highlight;
        color: $ln-color-text-light-active;
      }
    }

    &.ln-c-button--dark {
      color: $ln-color-text-dark;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-dark-background-highlight;
        color: $ln-color-text-dark-active;
      }
    }
  }

  .ln-c-button--outlined {
    border: $ln-border-size-large solid $ln-color-alpha-border;
    padding: calc(#{$ln-space} - #{$ln-border-size-large})
      calc(#{$ln-space-x2} - #{$ln-border-size-large});

    @include ln-hocus-not-disabled {
      border-color: $ln-color-alpha-border-active;
    }

    &.ln-c-button--beta {
      border-color: $ln-color-beta-border;

      @include ln-hocus-not-disabled {
        border-color: $ln-color-beta-border-active;
      }
    }

    &.ln-c-button--light {
      border-color: $ln-color-light-border;

      @include ln-hocus-not-disabled {
        border-color: $ln-color-light-border-active;
      }
    }

    &.ln-c-button--dark {
      border-color: $ln-color-dark-border;

      @include ln-hocus-not-disabled {
        border-color: $ln-color-dark-border-active;
      }
    }

    &:not(.ln-c-button--light):not(.ln-c-button--dark) {
      @include ln-when-disabled {
        @include ln-button-outlined-disabled-style;
      }
    }
  }

  .ln-c-button__underline {
    position: relative;

    &::after {
      background-color: $ln-color-text-alpha;
      bottom: 0;
      content: '';
      height: $ln-border-size-large;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(1);
      transition: transform $ln-transition-duration;
    }

    .ln-c-button--link-bare &::after {
      transform: scaleX(0);
    }

    .ln-c-button--beta &::after {
      background-color: $ln-color-text-beta;
    }

    .ln-c-button--light &::after {
      background-color: $ln-color-text-light;
    }

    .ln-c-button--dark &::after {
      background-color: $ln-color-text-dark;
    }

    :disabled &::after,
    .is-disabled &::after {
      display: none;
    }
  }

  .ln-c-button--link {
    @include ln-hocus-not-disabled {
      .ln-c-button__underline::after {
        background-color: $ln-color-text-alpha-active;
        transform: scaleX(1);
      }

      &.ln-c-button--beta .ln-c-button__underline::after {
        background-color: $ln-color-text-beta-active;
      }

      &.ln-c-button--light .ln-c-button__underline::after {
        background-color: $ln-color-text-light-active;
      }

      &.ln-c-button--dark .ln-c-button__underline::after {
        background-color: $ln-color-text-dark-active;
      }
    }
  }

  .ln-c-button--deep {
    padding-bottom: $ln-space-x3;
    padding-top: $ln-space-x3;

    @include ln-respond-to($ln-size-lg-breakpoint) {
      min-height: $ln-size-lg;
    }
  }

  .ln-c-button--full {
    width: 100%;
  }

  .ln-c-button--circle {
    border-radius: 100%;
    padding: 0;
  }

  .ln-c-button--hard,
  .ln-c-button--icon {
    padding: 0;
  }

  /* stylelint-disable selector-list-comma-newline-after */
  .ln-c-button--link,
  /// @deprecated - Use `.ln-c-button--link`
  .ln-c-button--text-only,
  /// @deprecated - Use `.ln-c-button--link`
  .ln-c-button--standalone-link {
    color: $ln-color-text-alpha;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;

    @include ln-hocus-not-disabled {
      background-color: $ln-color-alpha-background-highlight;
      color: $ln-color-text-alpha-active;
    }

    @include ln-when-disabled {
      @include ln-button-disabled-style;
    }

    &.ln-c-button--beta {
      color: $ln-color-text-beta;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-beta-background-highlight;
        color: $ln-color-text-beta-active;
      }
    }

    &.ln-c-button--light {
      color: $ln-color-text-light;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-light-background-highlight;
        color: $ln-color-text-light-active;
      }
    }

    &.ln-c-button--dark {
      color: $ln-color-text-dark;

      @include ln-hocus-not-disabled {
        background-color: $ln-color-dark-background-highlight;
        color: $ln-color-text-dark-active;
      }
    }
  }
  // stylelint-enable

  .ln-c-button--text,
  .ln-c-button--link {
    &:not(.ln-c-button--light):not(.ln-c-button--dark) {
      @include ln-when-disabled {
        @include ln-button-disabled-style;
      }
    }
  }

  .ln-c-button--nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ln-c-button--align-left {
    justify-content: flex-start;
    text-align: left;
  }

  .ln-c-button--align-right {
    justify-content: flex-end;
    text-align: right;
  }

  /// @deprecated - Use `.ln-c-button--outlined` or `.ln-c-button--text`
  .ln-c-button--secondary {
    background-color: $ln-color-beta-background;
    color: $ln-color-text-on-beta;

    @include ln-hocus-not-disabled {
      background-color: $ln-color-beta-background-active;
      color: $ln-color-text-on-beta-active;
    }
  }

  .ln-c-button--icon-text {
    align-items: center;
    display: inline-flex;
    line-height: 1;
    padding-bottom: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .ln-c-button__icon,
  .ln-c-icon.ln-c-button__icon {
    $icon-size: $ln-size-def;
    $icon-padding: ($icon-size - $ln-icon-space-large) / 2;

    $icon-size-outlined: $ln-size-def - $ln-border-size-large * 2;
    $icon-padding-outlined: ($icon-size-outlined - $ln-icon-space-large) / 2;

    height: $icon-size;
    padding: $icon-padding;
    width: $icon-size;

    .ln-c-button--outlined & {
      height: $icon-size-outlined;
      padding: $icon-padding-outlined;
      width: $icon-size-outlined;
    }

    .ln-c-button--outlined.ln-c-button--icon-text & {
      margin-right: $icon-padding - $icon-padding-outlined;
    }
  }
}
