@if $use-ln-icon {
  .ln-c-icon {
    display: inline-block;
    height: map-get($ln-icon-sizes, 'def');
    transition: transform $ln-transition-duration;
    vertical-align: middle;
    width: map-get($ln-icon-sizes, 'def');

    &.ln-c-icon--fixed {
      height: map-get($ln-icon-sizes-fixed, 'def');
      width: map-get($ln-icon-sizes-fixed, 'def');
    }
  }

  .ln-c-icon--left {
    @include ln-push-right;
  }

  .ln-c-icon--right {
    @include ln-push-left;
  }

  .ln-c-icon--rotate-180 {
    transform: rotate(-180deg);
  }

  .ln-c-icon--fill-current {
    fill: currentColor;
  }

  .ln-c-icon--stroke-current {
    stroke: currentColor;
  }

  @each $key, $value in $ln-icon-sizes {
    @if $key != 'def' {
      .ln-c-icon--#{$key} {
        height: $value;
        width: $value;

        // stylelint-disable-next-line max-nesting-depth
        &.ln-c-icon--fixed {
          height: map-get($ln-icon-sizes-fixed, $key);
          width: map-get($ln-icon-sizes-fixed, $key);
        }
      }
    }
  }
}
