////
/// @group utilities/colours
////

// Disabling stylelint to preserve indentation as sassdoc can't parse indented annotations
// stylelint-disable prettier/prettier
@each $key, $value in $ln-colors {
/// Sets the `color` to match a key existing in `$ln-colors`
/// @name Text colour
/// @example markup
///   <div class="ln-u-soft ln-u-color-orange">ln-u-color-orange</div>
///
  @if $use-ln-colors {
    .ln-u-color-#{$key} {
      color: $value !important;
    }
  }

/// Sets the `background-color` to match a key existing in `$ln-colors`
/// @name Background colour
/// @example markup
///   <div class="ln-u-soft ln-u-color-white ln-u-bg-color-orange-dark">ln-u-bg-color-orange-dark</div>
///
  @if $use-ln-bg-colors {
    .ln-u-bg-color-#{$key} {
      background-color: $value !important;
    }
  }
}

@each $key, $value in $ln-colors-theming {
/// Sets the `color` to match a key existing in `$ln-colors-theming`
/// @name Text colour
/// @example markup
///   <div class="ln-u-soft ln-u-color-orange">ln-u-color-alpha</div>
///
  @if $use-ln-colors {
    .ln-u-color-#{$key} {
      color: $value !important;
    }
  }

/// Sets the `background-color` to match a key existing in `$ln-colors-theming`
/// @name Background colour
/// @example markup
///   <div class="ln-u-soft ln-u-color-white ln-u-bg-color-beta-dark">ln-u-bg-color-orange-dark</div>
///
  @if $use-ln-bg-colors {
    .ln-u-bg-color-#{$key} {
      background-color: $value !important;
    }
  }
}
